import UserSidebar from "../UserSidebar";
import UserHeader from "../UserHeader";
import NavBarBottom from "../MainLayout/navbar-bottom";
import { isMobile } from "react-device-detect";
import TopHeader from "layouts/MainLayout/top-header";
import { useContext } from "react";
import { ThemeContext } from "context/ThemeContext";
import useQuery from "hooks/useQuery";
import RightSidebar from "layouts/MainLayout/right-sidebar";
import VAds from "components/v-ads";

const useShowRightSidebar = (query) => query?.is_show_right_sidebar !== "false";
export default function View(props) {
  const { themesSetting } = useContext(ThemeContext);

  const query = useQuery();
  const showRightSideBar = useShowRightSidebar(query);

  return (
    <>
      <VAds />
      {query && query.is_app === "1" ? (
        <div
          className="flex h-screen overflow-auto"
          style={themesSetting?.container}
        >
          {props.children}
        </div>
      ) : (
        <div
          className="flex relative w-full overflow-hidden z-100"
          style={themesSetting?.layout}
        >
          {/* <Advertisement /> */}
          <div
            className={`sticky flex-none z-50 top-0 left-0 bottom-0 border-r border-dashed !bg-cover !bg-no-repeat ${
              isMobile ? "h-14" : "h-screen"
            } `}
            style={themesSetting?.menus}
          >
            <div
              style={themesSetting?.bgHighLight}
              className="pointer-events-none filter blur-[150px] md:blur-[100px] xl:blur-[250px] lg:blur-[150px] rounded-full size-[150px] lg:size-[200px] xl:size-[300px] md:size-[100px] w-[150px] h-[200px] absolute left-0 top-0"
            ></div>
            <UserSidebar />
          </div>

          <div className={`grow flex-1 w-full mb-50px overflow-auto`}>
            <div
              className="fixed top-0 left-0 right-0 w-full h-[60px] flex items-center border-b border-dashed z-50 md:z-20 !bg-cover !bg-no-repeat"
              style={themesSetting?.header}
            >
              <TopHeader />
              <div className={`${isMobile && "hidden"}`}>
                <UserHeader />
              </div>
            </div>
            <div
              className="relative flex max-md:flex-col flex-1 h-[calc(100vh-166px)] md:h-[100vh] mt-[116px] md:mt-[60px] !bg-cover !bg-no-repeat overflow-y-hidden"
              style={themesSetting?.container}
            >
              {/* <div
                style={themesSetting?.bgHighLight}
                className="pointer-events-none filter blur-[150px] rounded-full xxl:w-[300px] xl:w-[150px] lg:w-[190px] md:w-[100px] w-[150px] xxl:h-[300px] xl:h-[150px] lg:h-[190px] md:h-[100px] h-[200px] absolute left-0 top-0"
              ></div> */}
              <div
                style={themesSetting?.bgHighLight}
                className="pointer-events-none filter blur-[150px] rounded-full xxl:w-[300px] xl:w-[150px] lg:w-[190px] md:w-[100px] w-[150px] xxl:h-[300px] xl:h-[150px] lg:h-[190px] md:h-[100px] h-[200px] absolute right-1/4 bottom-1/4"
              ></div>
              <div
                className={`${
                  isMobile && "mb-[0px]"
                } flex-1 w-[calc(100%-316px)] h-[calc(100vh-112px)] max-md:w-full overflow-y-auto px-2 py-2`}
                style={{
                  scrollbarColor: themesSetting?.scrollbar?.scrollbarColor,
                }}
              >
                {props.children}
              </div>
              {showRightSideBar && (
                <div
                  className={`sticky flex-none justify-center w-[316px] z-10 top-0 right-0 py-3 border-l border-dashed hidden md:block overflow-y-hidden ${
                    isMobile && "h-[calc(100vh-112px)]"
                  }`}
                  style={{ borderColor: themesSetting?.header?.borderColor }}
                >
                  <RightSidebar />
                </div>
              )}
            </div>
          </div>

          {isMobile && <NavBarBottom />}
        </div>
      )}
    </>
  );
}
