/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { DetailRightView, Title, MarginTop } from "../../../styles";
import { Controller, useForm } from "react-hook-form";
import API from "api";
import { toast } from "react-toastify";
import IDWithdraw from "./components/IDWithdraw";
import { formatNumber } from "utils/common";
import Verify from "../../verify";
import Network from "../../networks";
import { MasterContext } from "context/MasterContext";
import { useParams } from "react-router-dom";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import VTabs from "components/v-core/v-tabs";
import VButton from "components/v-core/v-button";
import { renderStock } from "utils/render";
import { ROUTES } from "config/routes";
import UserService from "api/users";
import Icons from "helper/icons";
import Store from "../../store-wallet/Store";

const WalletWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  fee,
  errorAmount,
  network,
  amount,
  handleChange,
  onBlurTo,
  setNetwork,
  stock,
  setValue,
}) => {
  const stockName = String(tokenName).toUpperCase();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="p-[15px]">
      <Controller
        name="to"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div>
            <div className="relative">
              <div className="flex justify-between items-center mb-2">
                <label className="font-bold">{`Stock ${stockName} Address`}</label>
              </div>
              <VInput
                transparent={true}
                className="!bg-transparent"
                placeholder="Please enter"
                hasError={errors.to}
                {...field}
                onBlur={(e) => onBlurTo(e.target.value)}
              />
              <Icons
                className="absolute right-2 top-1/2 translate-y-1/5 cursor-pointer"
                iconName="wallets"
                iconClassName="w-5 h-5"
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
              <Store stock={tokenName} isOpen={isOpen} onChange={setValue} />
          </div>
        )}
      />
      <MarginTop mt="20px" />
      <Network stock={stock} network={network} setNetwork={setNetwork} />
      <MarginTop mt="20px" />
      <VInput
        className="w-full mb-0 !bg-transparent"
        isCurrency={true}
        type="text"
        transparent={true}
        label={`Stock  ${stockName}  Amount`}
        placeholder="0.00"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={<VButton onClick={setMaxAmount}>MAX</VButton>}
        suffixClassName="!-right-3"
        onValueChange={(e) => handleChange(e)}
      />
      <p className="mt-4">
        Maximum {stockName} Available:{" "}
        <span className="text-red-500">{userBalance[tokenName]}</span>{" "}
        {stockName}
      </p>
      <p>
        Withdraw fee stock {stockName}:{" "}
        <span className="text-red-500">{fee.amount}</span>{" "}
        {String(fee.stock || "").toUpperCase()}
      </p>
      <div className="flex items-start pt-[6px]">
        <img
          src="/assets/images/icons/warning.svg"
          alt="warning"
          className="mr-[5px]"
        />
        <p className="text-[10px]">
          The above fees are not fixed, can vary depending on the state of the
          blockchain networks hihi
        </p>
      </div>
      <div className="flex items-start pt-[6px]">
        <img
          src="/assets/images/icons/warning.svg"
          alt="warning"
          className="mr-[5px]"
        />
        <p className="text-[10px]">
          Estimated time to complete: less than a minute
        </p>
      </div>
    </div>
  );
};

const EmailWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();

  return (
    <div className="p-[15px]">
      <Controller
        name="to"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <VInput
              transparent={true}
              className="!bg-transparent"
              label="Email"
              placeholder="Input Email"
              hasError={errors.to}
              {...field}
            />
          );
        }}
      />
      <MarginTop mt="20px" />

      <VInput
        className="w-full !bg-transparent"
        transparent={true}
        label={`Stock ${stockName} Amount`}
        placeholder="0.00"
        isCurrency={true}
        type="text"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={<VButton onClick={setMaxAmount}>MAX</VButton>}
        suffixClassName="!-right-3"
        onValueChange={(e) => handleChange(e)}
      />

      {/* <MarginTop mt="20px" /> */}
      <p className="mt-4">
        Maximum {stockName} Available:{" "}
        <span className="text-red-400">{userBalance[tokenName]}</span>{" "}
        {stockName}
      </p>
      <p>
        You can <span className="text-green-400">buy</span> or{" "}
        <span className="text-orange-400">deposit</span> {stockName}
      </p>
      <MarginTop mt="20px" />

      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <VInput
              type={"textarea"}
              height="100px"
              transparent={true}
              className="!bg-transparent"
              label="Message"
              placeholder={`Welcome to ${stockName} Stock`}
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />
    </div>
  );
};

const PhoneWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();
  const { themesSetting } = useContext(ThemeContext);

  return (
    <div className="p-[15px]">
      <div>
        <p
          className="w-fit py-[5px] px-[10px] text-[14px] rounded"
          style={themesSetting?.card}
        >
          You can donate coin to your friends via phone numbers, even when your
          friends have not added their phone number to Stock.
        </p>

        <div className="flex mt-5">
          <div className="w-full">
            <Controller
              name="to"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <VInput
                    label={"Phone number"}
                    type={"number"}
                    transparent={true}
                    className="!bg-transparent"
                    placeholder="Phone number"
                    hasError={errors.to}
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>

      <VInput
        className="w-full !bg-transparent mb-0 "
        wrapperClassName="mt-2"
        transparent={true}
        label={`Stock ${stockName} Amount`}
        placeholder="0.00"
        isCurrency={true}
        type="text"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={<VButton onClick={setMaxAmount}>MAX</VButton>}
        suffixClassName="!-right-3"
        onValueChange={(e) => handleChange(e)}
      />

      <p className="mt-5">
        Maximum {stockName} Available:{" "}
        <span className="text-red-400">{userBalance[tokenName]}</span>{" "}
        {stockName}
      </p>
      <p>
        You can <span className="text-green-400">buy</span> or{" "}
        <span className="text-orange-400">deposit</span> {stockName}
      </p>
      <MarginTop mt="20px" />

      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <VInput
              type={"textarea"}
              height="100px"
              transparent={true}
              className="!bg-transparent"
              label="Message"
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />
    </div>
  );
};

const View = () => {
  const { themesSetting } = useContext(ThemeContext);
  const { balances, stocks } = useContext(MasterContext);
  const location: any = useLocation();
  const history = useHistory();
  const { stock: stockParam } = useParams();

  const address = queryString.parse(location.search)?.address;
  const amountStock = queryString.parse(location.search)?.amountStock;

  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(null);
  const [showVerify, setShowVerify] = useState(false);
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });

  const [amount, setAmount] = useState<number>(null);
  const [network, setNetwork] = useState();

  const [errorAmount, setErrorAmount] = useState<boolean>(false);
  const [profileDeposit, setProfileDeposit] = useState<any>(null);

  const [tabParent, setTabParent] = useState(null);

  const [tabActive, setTabActive] = useState(null);

  const tabsParent = [
    {
      name: "On-Chain",
      value: "chain",
    },
    {
      name: "Internal",
      value: "internal",
    },
  ];

  const tabs = [
    {
      name: "Email",
      value: "email",
    },
    {
      name: "Phone",
      value: "phone",
    },
    {
      name: "ID",
      value: "id",
    },
  ];

  useEffect(() => {
    setTabActive(tabs[0].value);
    setTabParent(tabsParent[1].value);
  }, []);

  useEffect(() => {
    if (address) {
      getProfileDeposit(String(address), "wallet");
    }
  }, [address]);

  useEffect(() => {
    setShowVerify(false);
  }, [tabActive]);

  useEffect(() => {
    if (stocks) setStock(stocks.find((c) => c.symbol === stockParam));
  }, [stocks, stockParam]);

  useEffect(() => {
    if (Number(amountStock) > 0) {
      setAmount(Number(amountStock));
    }
    if (address) {
      setTabParent("chain");
      setValue("to", address);
    }
  }, []);

  const handleTabs = (tab: string) => {
    setTabActive(tab);
    setErrorAmount(false);
    setAmount(null);
    setValue("to", null);
    setProfileDeposit(null);
  };

  const handleChangeTabParent = (tab: string) => {
    setTabParent(tab);
  };

  const {
    control,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    setAmount(e);
  };

  const handleSubmitWithdraw = async (data) => {
    const body = {
      method: tabActive,
      note: data.note,
      to: data.to,
      token: stockParam,
      amount: Number(amount),
      network,
      verify,
    };
    try {
      if (tabParent === "chain" && !network)
        return toast.error("Please choose network!");
      if (amount <= 0 || !amount) {
        setErrorAmount(true);
      } else {
        if (!showVerify) {
          setShowVerify(true);
          return;
        }
        setLoading(true);
        if (tabParent === "chain") {
          await API.TRANSACTION.withdraw({ body });
        } else {
          await API.TRANSACTION.transfer({ body });
        }
        setLoading(false);
        setShowVerify(false);
        toast.success("Withdraw successful!");
        setVerify({
          email_code: "",
          google_code: "",
        });
        history.push(`${ROUTES.MY_WALLETS}/${stockParam}/history?tab=withdraw`);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.errors || "Withdraw failed!");
    }
  };

  const setMaxAmount = () => {
    setAmount(balances[stockParam]);
  };

  const onBlurTo = (value: any) => {
    getProfileDeposit(value, tabActive);
  };

  const getProfileDeposit = async (value: string, type: string) => {
    try {
      const user: any = await UserService.getUserByOption({
        query: `type=${type}&address=${value}`,
      });
      if (user) {
        setProfileDeposit(user);
      } else {
        setProfileDeposit(null);
      }
    } catch (error) {
      setProfileDeposit(null);
    }
  };

  const feeTransfer =
    stock && stock.fee_transfer
      ? stock.fee_transfer
      : { stock: stockParam, percent: 0 };
  // const amount = watch("amount");
  const amountFee = formatNumber(((+amount || 0) * feeTransfer.percent) / 100);

  const formProps = {
    userBalance: balances,
    control,
    errors,
    tokenName: stockParam,
    fee: {
      amount: amountFee,
      stock: feeTransfer.stock,
    },
    setMaxAmount,
    setValue,
  };

  return (
    <DetailRightView>
      <Title bgColor={"grey200"} just="space-between">
        <p>Transfer Stock ({renderStock(stockParam)})</p>
      </Title>
      <VTabs
        tabActive={tabParent}
        tabs={tabsParent}
        onChangeTabs={handleChangeTabParent}
      />
      {profileDeposit && (
        <div className="px-3 py-2">
          <div style={themesSetting.card} className="p-2 rounded-md">
            <div className="flex">
              <span>Name:</span>
              <div className="ml-1 font-bold">{profileDeposit.fullName}</div>
            </div>
            <div className="flex">
              <span>ID:</span>
              <div className="ml-1 font-bold">{profileDeposit.refer_code}</div>
            </div>
            <div className="flex">
              <span>Address:</span>
              <div className="ml-1 font-bold">
                {profileDeposit?.address?.detail}
              </div>
            </div>
            {profileDeposit.phone && (
              <div className="flex">
                <span>Phone:</span>
                <div className="ml-1 font-bold">{profileDeposit.phone}</div>
              </div>
            )}
            {/* {(profileDeposit?.organizationsData ||
            profileDeposit?.businessData) && (
            <div>
              <div>
                <span>Name:</span>
                <div>{profileDeposit.fullName || profileDeposit.username}</div>
              </div>
              <div>
                <span>ID:</span>
                <div>{profileDeposit.refer_code}</div>
              </div>

              <div>
                <span>Adress:</span>
                <div>{profileDeposit.address}</div>
              </div>
              {profileDeposit.phone && (
                <div>
                  <span>Phone:</span>
                  <div>{profileDeposit.phone}</div>
                </div>
              )}
              {(profileDeposit?.organizationsData ||
                profileDeposit?.businessData) && (
                <div>
                  {profileDeposit?.organizationsData
                    ? "Organization"
                    : "Business"}{" "}
                  Name: &nbsp;
                  <b>
                    {profileDeposit?.organizationsData?.organization ||
                      profileDeposit?.businessData?.business}
                  </b>
                </div>
              )}
            </div>
          )} */}
          </div>
        </div>
      )}
      {/* Withdraw on chain */}
      {tabParent === "chain" && (
        <form onSubmit={handleSubmit(handleSubmitWithdraw)}>
          {showVerify ? (
            <Verify verify={verify} setVerify={setVerify} />
          ) : (
            <WalletWithdraw
              {...formProps}
              stock={stock}
              network={network}
              amount={amount}
              errorAmount={errorAmount}
              setNetwork={setNetwork}
              onBlurTo={onBlurTo}
              handleChange={handleChange}
            />
          )}
          <div className="flex justify-center items-center w-full mb-2">
            <VButton
              radius="full"
              loading={loading}
              className="w-[300px] !mb-5"
            >
              Submit
            </VButton>
          </div>
        </form>
      )}

      {/* Withdraw internal */}
      {tabParent === "internal" && (
        <div>
          <VTabs
            className="justify-center"
            tabActive={tabActive}
            tabs={tabs}
            onChangeTabs={handleTabs}
          />

          <form onSubmit={handleSubmit(handleSubmitWithdraw)}>
            {tabActive === "email" &&
              (showVerify ? (
                <Verify verify={verify} setVerify={setVerify} />
              ) : (
                <EmailWithdraw
                  {...formProps}
                  amount={amount}
                  errorAmount={errorAmount}
                  handleChange={handleChange}
                />
              ))}

            {tabActive === "phone" &&
              (showVerify ? (
                <Verify verify={verify} setVerify={setVerify} />
              ) : (
                <PhoneWithdraw
                  {...formProps}
                  amount={amount}
                  errorAmount={errorAmount}
                  handleChange={handleChange}
                />
              ))}

            {tabActive === "id" &&
              (showVerify ? (
                <Verify verify={verify} setVerify={setVerify} />
              ) : (
                <IDWithdraw
                  {...formProps}
                  amount={amount}
                  errorAmount={errorAmount}
                  handleChange={handleChange}
                />
              ))}
            <div className="flex justify-center items-center w-full mb-2">
              <VButton
                radius="full"
                loading={loading}
                className="w-[300px] !mb-5"
              >
                Submit
              </VButton>
            </div>
          </form>
        </div>
      )}
    </DetailRightView>
  );
};

export default View;
