import { format } from "date-fns";
import { Calendar } from "phosphor-react";
import {
  Popover,
  PopoverAction,
  PopoverContent,
  Button,
  DatePicker,
} from "keep-react";

type DatePickerProps = {
  date: Date | undefined;
  setDate: (date: Date) => void;
  isEdit?: boolean;
  label: string;
  isError?: boolean;
  isEndDate?: boolean;
};

export const DatePickerComponent = ({
  date,
  setDate,
  isEdit,
  label,
  isError,
  isEndDate,
}: DatePickerProps) => {
  const handleDatePickerSelect = (selectedDate: Date | null) => {
    if (selectedDate) {
      const currentDate = new Date();
      const newDate = new Date(selectedDate);

      newDate.setHours(currentDate.getHours());
      newDate.setMinutes(currentDate.getMinutes());
      newDate.setSeconds(currentDate.getSeconds());

      setDate(newDate);
    }
  };

  const handleInputChange = (
    value: string,
    type: "day" | "month" | "year" | "hour" | "minute" | "second"
  ) => {
    if (!date) return;
    const newDate = new Date(date);
    switch (type) {
      case "day":
        newDate.setDate(Number(value));
        break;
      case "month":
        newDate.setMonth(Number(value) - 1);
        break;
      case "year":
        newDate.setFullYear(Number(value));
        break;
      case "hour":
        newDate.setHours(Number(value));
        break;
      case "minute":
        newDate.setMinutes(Number(value));
        break;
      case "second":
        newDate.setSeconds(Number(value));
        break;
    }
    setDate(newDate);
  };

  return (
    <div>
      <div
        className={`flex items-center gap-4 ${
          isEndDate || isError ? "text-red-500" : "text-white"
        }`}
      >
        <span className="text-body-4">{label}</span>
        <div className="flex gap-2">
          <input
            type="number"
            className={`w-[50px] text-center rounded-md bg-gray-500 ${
              isEndDate || isError ? "border-red-500" : "text-white"
            }`}
            value={date ? format(date, "dd") : ""}
            onChange={(e) => handleInputChange(e.target.value, "day")}
            readOnly={!isEdit}
          />
          <input
            type="number"
            className={`w-[50px] text-center rounded-md bg-gray-500 ${
              isEndDate || isError ? "border-red-500" : "text-white"
            }`}
            value={date ? format(date, "MM") : ""}
            onChange={(e) => handleInputChange(e.target.value, "month")}
            readOnly={!isEdit}
          />
          <input
            type="number"
            className={`w-[70px] text-center rounded-md bg-gray-500 ${
              isEndDate || isError ? "border-red-500" : "text-white"
            }`}
            value={date ? format(date, "yyyy") : ""}
            onChange={(e) => handleInputChange(e.target.value, "year")}
            readOnly={!isEdit}
          />
        </div>
        <div className="flex gap-2">
          <input
            type="number"
            className={`w-[50px] text-center rounded-md bg-gray-500 ${
              isEndDate || isError ? "border-red-500" : "text-white"
            }`}
            value={date ? format(date, "HH") : ""}
            onChange={(e) => handleInputChange(e.target.value, "hour")}
            readOnly={!isEdit}
          />
          <input
            type="number"
            className={`w-[50px] text-center rounded-md bg-gray-500 ${
              isEndDate || isError ? "border-red-500" : "text-white"
            }`}
            value={date ? format(date, "mm") : ""}
            onChange={(e) => handleInputChange(e.target.value, "minute")}
            readOnly={!isEdit}
          />
          <input
            type="number"
            className={`w-[50px] text-center rounded-md bg-gray-500 ${
              isEndDate || isError ? "border-red-500" : "text-white"
            }`}
            value={date ? format(date, "ss") : ""}
            onChange={(e) => handleInputChange(e.target.value, "second")}
            readOnly={!isEdit}
          />
        </div>
        {isEdit && (
          <Popover>
            <PopoverAction asChild>
              <Button
                color="secondary"
                size="sm"
                className="ml-2 border border-white text-white rounded-md px-2"
              >
                <Calendar size={20} />
              </Button>
            </PopoverAction>
            <PopoverContent align="start" className="max-w-min border-0">
              <DatePicker
                numberOfMonths={1}
                mode="single"
                selected={date}
                onSelect={handleDatePickerSelect}
                showOutsideDays={true}
              />
            </PopoverContent>
          </Popover>
        )}
      </div>
    </div>
  );
};
