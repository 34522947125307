import { ThemeContext } from "context/ThemeContext";
import { useContext, useEffect, useRef, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import VDropdown from "components/v-core/v-dropdown";
import SettingService from "api/settings";
import VButton from "components/v-core/v-button";
import BankService from "api/banks";
import { toast } from "react-toastify";
import { MasterContext } from "context/MasterContext";
import CardItem from "./components/CardItem";
import VModal from "components/v-core/v-modal";
import VDownLoad from "components/v-download";
import { exportComponentAsPNG } from "react-component-export-image";
import VInput from "components/v-core/v-input";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

const BankCard = () => {
  const { themesSetting } = useContext(ThemeContext);
  const { auth } = useContext(MasterContext);

  const [listBanks, setListBanks] = useState(null);
  const [cardsData, setCardsData] = useState<any>([]);
  const [listCards, setListCards] = useState<any>([]);
  const [bankName, setBankName] = useState("");
  const [bankSelected, setBankSelected] = useState(null);
  const [cardSelected, setCardSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const imgFrontRef = useRef();
  const imgBackRef = useRef();
  const [openModal, setOpenModal] = useState("");
  const [cardDownload, setCardDownload] = useState(null);

  useEffect(() => {
    getBankCard();
    getCardsData();
  }, []);

  useEffect(() => {
    if (listBanks) {
      setBankName(listBanks[0].name);
    }
  }, [listBanks]);

  useEffect(() => {
    if (bankName && listBanks) {
      const _selected = listBanks?.find((el) => el.name === bankName);
      setBankSelected(_selected);
    }
  }, [bankName, listBanks]);

  useEffect(() => {
    if (bankSelected && cardsData) {
      const _newList = cardsData?.filter(
        (el) => el.bankName === bankSelected?.name
      );
      if (_newList) {
        setListCards(_newList);
      } else {
        setListCards([]);
      }
    }
  }, [bankSelected, cardsData]);

  // Get list banks
  const getBankCard = async () => {
    const res: any = await SettingService.getSettingByName("bank_cards");
    setListBanks(res?.setting?.value);
  };

  // Get data bank
  const getCardsData = async () => {
    const res: any = await BankService.getBanks();
    if (res) {
      setCardsData(res);
    } else {
      setCardsData([]);
    }
  };

  const handleChooseBank = (value) => {
    setBankName(value);
  };

  const handleChangeInformation = (type: string, item: any, value: any) => {
    setBankSelected((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        userInputs: prev[type]?.userInputs?.map((e) =>
          e.id === item.id ? { ...e, value: value } : e
        ),
      },
    }));
  };

  const handleAddCard = async () => {
    try {
      if (bankName !== "") {
        await BankService.createBank({
          body: {
            ...bankSelected,
            user_id: auth._id,
            bankName: bankName,
          },
        });
        setOpenModal("");
        getCardsData();
      } else {
        toast.error("Please choose a bank name");
      }
    } catch (error) {}
  };

  const handleDeleteCard = async (bankId: string) => {
    try {
      await BankService.deleteBank({
        params: { id: bankId },
      });
      setOpenModal("");
      getCardsData();
    } catch (error) {}
  };

  const onOpenModal = (type: string, card?: any) => {
    setOpenModal(type);
    if (type === "download") {
      setCardDownload(card);
    } else if (type === "delete") {
      setCardSelected(card);
    }
  };

  const downloadImage = async () => {
    setIsLoading(true);
    const cardRefs = [imgFrontRef, imgBackRef];

    const fileNames = [
      `front_card_${new Date().getTime()}` || "vzonex",
      `back_card_${new Date().getTime()}` || "vzonex",
    ];

    for (let i = 0; i < cardRefs.length; i++) {
      await exportComponentAsPNG(cardRefs[i], { fileName: fileNames[i] });
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full flex flex-col gap-y-6 m-auto">
      <div className="flex flex-col gap-y-2 z-50">
        <label className="px-2">Choose bank</label>
        <div className="flex gap-2">
          <VDropdown
            customClassName="w-[200px]"
            value={bankName}
            options={
              listBanks?.map((item: any) => ({
                value: item.name,
                label: item.name,
              })) || []
            }
            onChange={handleChooseBank}
          />
          <VButton
            className="w-8 h-8"
            radius="full"
            onClick={() => onOpenModal("bank")}
            iconLeft={<IoMdAdd />}
          />
        </div>
      </div>
      {listCards?.map((card) => (
        <div
          className="relative w-full flex flex-col gap-y-3 p-4 rounded"
          style={themesSetting.card}
          key={card?._id}
        >
          <div className=" flex max-2xl:flex-col items-center gap-2 ">
            <div className="w-1/2 max-2xl:w-full z-30">
              <div className="w-full flex justify-center">
                <span className="font-bold text-lg">Front</span>
              </div>
              <CardItem
                data={card}
                type="front"
              />
            </div>
            <div className="w-1/2 max-2xl:w-full text-center z-30">
              <span className="font-bold text-lg">Back</span>
              <CardItem
                data={card}
                type="back"
              />
            </div>
          </div>
          <div className="absolute top-0 right-0 flex items-center gap-x-2">
              <VButton
                className="w-6 h-6"
                onClick={() => onOpenModal("delete", card)}
              >
                <span className="text-xl font-bolt text-red-500">-</span>
              </VButton>
          </div>
          <div className="flex justify-center w-full mt-4 gap-x-2 -z-1">
              <VButton
                className="!w-[150px]"
                onClick={() => onOpenModal("download", card)}
              >
                Download
              </VButton>
          </div>
        </div>
      ))}
      <VModal
        isOpen={openModal !== ""}
        fullscreen={true}
        showImage={openModal === "download"}
        onCancel={() => setOpenModal("")}
        onConfirm={openModal === "bank" ? () => handleAddCard() : null}
        onDelete={
          openModal === "delete"
            ? () => handleDeleteCard(cardSelected._id)
            : null
        }
      >
        {openModal === "download" && (
          <VDownLoad
            download={downloadImage}
            closeModal={() => setOpenModal("")}
            loading={isLoading}
          >
            <div className="w-full" ref={imgFrontRef}>
              <CardItem data={cardDownload} type="front"/>
            </div>
            <div className="w-full" ref={imgBackRef}>
              <CardItem data={cardDownload} type="back"/>
            </div>
          </VDownLoad>
        )}
        {openModal === "bank" && (
          <div className="flex flex-col gap-y-3">
            <h3>Please input your information</h3>
            {bankSelected?.front?.userInputs?.length > 0 &&
              bankSelected?.front?.userInputs?.map((el) => (
                <div className="flex flex-col items-start" key={el?.id}>
                  <label htmlFor={el?.label}>{el?.label}</label>
                  <VInput
                    value={el?.value}
                    placeholder={el?.placeholder}
                    onChange={(e) =>
                      handleChangeInformation("front", el, e.target.value)
                    }
                  />
                </div>
              ))}
            {bankSelected?.back?.userInputs?.length > 0 &&
              bankSelected?.back?.userInputs?.map((el) => (
                <div className="flex flex-col items-start" key={el?.id}>
                  <label htmlFor={el?.label}>{el?.label}</label>
                  <VInput
                    value={el?.value}
                    placeholder={el?.placeholder}
                    onChange={(e) =>
                      handleChangeInformation("back", el, e.target.value)
                    }
                  />
                </div>
              ))}
          </div>
        )}
        {openModal === "delete" && (
          <div className="flex items-center justify-center gap-x-3">
            <ExclamationTriangleIcon
              aria-hidden="true"
              className="h-6 w-6 text-red-600"
            />
            <span className="">Are you sure delete this card?</span>
          </div>
        )}
      </VModal>
    </div>
  );
};

export default BankCard;
