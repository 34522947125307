import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string"

const getStore = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/stores/getByUser?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went wrong!"));
      })
      .catch((err) => reject(parseErrorResponse(err)));
  });

const createStore = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/stores`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went wrong!"));
      })
      .catch((err) => reject(parseErrorResponse(err)));
  });

const updateStore = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/stores/${req.params.storeId}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went wrong!"));
      })
      .catch((err) => reject(parseErrorResponse(err)));
  });

const deleteStore = (storeId) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`/stores/${storeId}`) 
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went wrong!"));
      })
      .catch((err) => reject(parseErrorResponse(err)));
  });

const APIs = {
  getStore,
  createStore,
  updateStore,
  deleteStore,
};

export default APIs;
