import { useState, useEffect } from "react";
import VButton from "components/v-core/v-button";
import { DatePickerComponent } from "./DateTime";

type IProps = {
  isEdit?: boolean;
  data: any;
  setData: (newData: any) => void;
};

const Profit = (props: IProps) => {
  const { isEdit = false, data, setData } = props;
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    data?.startDate || null
  );

  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
    data?.endDate || null
  );

  const handleSetSelectedDate = (date: Date, type: "start" | "end") => {
    type === "start" ? setSelectedStartDate(date) : setSelectedEndDate(date);
    setData((prevData: any) => ({
      ...prevData,
      profit: {
        ...prevData.profit,
        ...(type === "start" ? { startDate: date } : {}),
        ...(type === "end" ? { endDate: date } : {}),
      },
    }));
  };

  const getEventStatus = (startDate: string, endDate: string) => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (now < start) {
      return "Sắp diễn ra";
    } else if (now > start && now < end) {
      return "Đang diễn ra";
    } else {
      return "Đã kết thúc";
    }
  };

  const eventStatus = getEventStatus(
    props?.data?.profit?.startDate || "",
    props?.data?.profit?.Date || ""
  );

  const validateDate = (start: Date | null, end: Date | null) => {
    if (!start || !end) return false;
    return start <= end;
  };

  const isError = !validateDate(selectedStartDate, selectedEndDate);

  useEffect(() => {
    const _selectedEndDate = props?.data?.profit?.endDate;
    const _selectedStartDate = props?.data?.profit?.startDate;

    setSelectedEndDate(_selectedEndDate);
    setSelectedStartDate(_selectedStartDate);
  }, [props]);

  return (
    <div className="flex justify-between w-full">
      <div className="flex flex-col gap-y-3 justify-center items-center">
        <div className="flex gap-x-2">
          <VButton className="px-3" type="primary">
            Quan tâm (0)
          </VButton>
          <VButton className="px-3" type="secondary">
            Tham gia (0)
          </VButton>
          <VButton className="px-3" type="outline">
            Chia sẻ (0)
          </VButton>
        </div>
        <div className="w-[150px] h-[150px] p-2 border">
          <img src="" alt="" />
        </div>
      </div>
      <div className="flex flex-col gap-y-3">
        {selectedStartDate && selectedEndDate && (
          <div
            className={`text-center text-xl text-gray-4 ${
              eventStatus === "Đang diễn ra" ? "!text-[#00AC11]" : ""
            }`}
          >
            {eventStatus}
          </div>
        )}
        <div className="flex justify-center items-center gap-x-2">
          <DatePickerComponent
            date={selectedStartDate}
            setDate={(date) => handleSetSelectedDate(date, "start")}
            isEdit={isEdit}
            label="Bắt đầu"
            isError={isError && !!selectedStartDate}
          />
        </div>
        <div className="flex justify-center items-center gap-x-2">
          <DatePickerComponent
            date={selectedEndDate}
            setDate={(date) => handleSetSelectedDate(date, "end")}
            isEdit={isEdit}
            label="Kết thúc"
            isError={isError && !!selectedEndDate}
            isEndDate={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Profit;
