import React, { useState, useEffect, useContext } from "react";
import VModal from "components/v-core/v-modal";
import APIs from "api";
import VInput from "components/v-core/v-input";
import VButton from "components/v-core/v-button";
import { ThemeContext } from "context/ThemeContext";
import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";

type IProps = {
  stock?: string;
  isOpen?: boolean;
  onChange?: any;
};

const DATA_DEFAULT = {
  name: "",
  address: "",
  chain_type: "bsc",
}

const Store = (props: IProps) => {
  const { stock, isOpen, onChange } = props;
  const { themesSetting } = useContext(ThemeContext);

  const [store, setStore] = useState([]);
  const [formData, setFormData] = useState(DATA_DEFAULT);
  const [error, setError] = useState({
    address: "",
    name: "",
    remember: "",
  });
  const [openModal, setOpenModal] = useState("");
  const [storeSelected, setStoreSelected] = useState<any>(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    fetchStore();
  }, []);

  useEffect(() => {
    if (storeSelected) {
      setFormData((prev: any) => ({
        ...prev,
        name: storeSelected.name,
        address: storeSelected.address,
      }));
    } else {
      setFormData(DATA_DEFAULT);
    }
  }, [storeSelected]);

  const fetchStore = async () => {
    try {
      const data: any = await APIs.STORE.getStore({
        query: {
          stock,
        },
      });
      if (data) setStore(data);
    } catch (error) {
      console.error("Error fetching store:", error);
    }
  };

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (error[name]) {
      setError((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const validateForm = () => {
      const newErrors: any = {};
      if (!formData.address.trim()) {
        newErrors.address = "Address is required.";
      }
      if (!formData.name.trim()) {
        newErrors.name = "Name is required.";
      }
      setError(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    try {
      if (validateForm()) {
        if (!storeSelected) {
          await APIs.STORE.createStore({
            body: { ...formData, stock: stock },
          });
          toast.success("Created success");
        } else {
          await APIs.STORE.updateStore({
            params: {storeId: storeSelected._id},
            body: { ...formData, stock: stock },
          })
          toast.success("Updated success");
        }
        fetchStore();
        setOpenModal("");
        setStoreSelected(null);
      } else {
        return error;
      }
    } catch (error) {
      toast.error(`"Error adding store: ${error}`);
    }
  };

  const handleDeleteStore = async (storeId: string) => {
    try {
      await APIs.STORE.deleteStore(storeId);
      setOpenModal("");
      toast.success("Deleted");
      fetchStore();
      setStoreSelected(null);
      onChange("to", "");
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  return (
    <>
      <div
        className={`max-h-fix transition-all duration-500 ease-linear overflow-hidden rounded ${
          isOpen && "p-2 mt-3"
        }`}
        style={
          isOpen
            ? {
                ...themesSetting.card,
                height: `${store?.length * 65 + 80}px`,
              }
            : { height: 0 }
        }
      >
        <div className="flex flex-col gap-y-2 items-center ">
          {store?.length > 0 ? (
            <>
              {store.map((el: any) => (
                <div
                  key={el._id}
                  className={`${
                    el._id === storeSelected?._id && "shadow-xl border"
                  } relative w-full flex flex-col gap-y-1 p-2 cursor-pointer rounded hover:shadow-xl hover:border`}
                  onClick={() => {
                    onChange("to", el.address);
                    setStoreSelected(el);
                  }}
                >
                  <span className="font-semibold">{el.name}</span>
                  <span className="text-sm font-light">{el.address}</span>
                  <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center gap-x-2">
                    <TrashIcon
                      className=" size-5"
                      onClick={() => {
                        setStoreSelected(el);
                        setOpenModal("delete");
                      }}
                    />
                    <PencilIcon
                      className="size-5"
                      onClick={() => {
                        setStoreSelected(el);
                        setOpenModal("add_or_edit");
                      }}
                    />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span>{`You don't have any ${stock.toUpperCase()} wallet`}</span>
          )}
          <VButton
            type="outline"
            className="!bg-transparent !border !border-dashed"
            onClick={() => {
              setOpenModal("add_or_edit");
              setStoreSelected(null);
            }}
          >
            Add new wallet
          </VButton>
        </div>
      </div>
      <VModal
        isOpen={openModal === "add_or_edit"}
        title="Add Store"
        onCancel={() => setOpenModal("")}
        onConfirm={(e) => handleSubmit(e)}
        confirmLabel="Confirm"
        cancelLabel="Close"
      >
        <div className="space-y-4">
          <VInput
            transparent={true}
            className="text-black-1"
            type="text"
            placeholder="Please input your withdrawal store address"
            name="address"
            value={formData.address}
            onChange={(e) => handleInputChange("address", e.target.value)}
            hasError={Boolean(error.address)}
            errMessage={error.address}
          />
          <VInput
            transparent={true}
            className="text-black-1"
            placeholder="Add a remark"
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            hasError={Boolean(error.name)}
            errMessage={error.name}
          />
        </div>
      </VModal>
      <VModal
        isOpen={openModal === "delete"}
        title={"Do you want delete this store"}
        onCancel={() => setOpenModal("")}
        onDelete={() => handleDeleteStore(storeSelected._id)}
      ></VModal>
    </>
  );
};
export default Store;
