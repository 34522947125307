import APIs from "api";
import VModal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import UploadDocs from "./upload/UploadDocs";

type IProps = {
  data?: any;
  setData?: (e: any) => void;
  isEdit?: boolean;
};

const Footer = (props: IProps) => {
  const { data, setData, isEdit } = props;

  const { themesSetting } = useContext(ThemeContext);

  const params = useParams();
  const [image, setImage] = useState(null);
  const [isModalUpload, setIsModalUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [linkSocial, setLinkSocial] = useState(null);
  const [importantLinks, setImportantLinks] = useState(null);
  const [information, setInformation] = useState(null);
  const [aboutUsItems, setAboutUsItems] = useState(null);

  useEffect(() => {
    setLinkSocial(data?.footer?.social);
    setImportantLinks(data?.footer?.importantLinks);
    setInformation(data?.footer?.information);
    setAboutUsItems(data?.footer?.aboutUs?.items);
  }, [
    data?.footer?.social,
    data?.footer?.importantLinks,
    data?.footer?.information,
    data?.footer?.aboutUs?.items,
  ]);

  const setFile = (f: any, id: string) => {
    setImage((pre) => ({
      ...pre,
      [id]: f,
    }));
  };

  const onBlurDocument = (
    e: any,
    type: string,
    name: string,
    parent?: string
  ) => {
    if (parent) {
      setData((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [parent]: {
            ...prev[type][parent],
            [name]: e.target.innerText,
          },
        },
      }));
    }
    setData((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [name]: e.target.innerText,
      },
    }));
  };

  const openModal = (type: string) => {
    setTypeModal(type);
  };

  const handleChangeInput = (name: string, value: string, id?: string) => {
    if (typeModal === "Link social") {
      setLinkSocial((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                link: value,
              }
            : { ...el }
        )
      );
    } else if (typeModal === "Important link") {
      setImportantLinks((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                [name]: value,
              }
            : { ...el }
        )
      );
    } else if (typeModal === "Information") {
      setInformation((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                [name]: value,
              }
            : { ...el }
        )
      );
    } else if (typeModal === "About us") {
      setAboutUsItems((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                [name]: value,
              }
            : { ...el }
        )
      );
    }
  };

  const handleAdd = () => {
    if (typeModal === "Important link") {
      setImportantLinks((prev) =>
        prev.concat({
          id: uuidv4(),
          label: "",
          value: "",
        })
      );
    } else if (typeModal === "Information") {
      setInformation((prev) =>
        prev.concat({
          id: uuidv4(),
          label: "",
          value: "",
        })
      );
    } else if (typeModal === "About us") {
      setAboutUsItems((prev) =>
        prev.concat({
          id: uuidv4(),
          label: "",
          value: "",
        })
      );
    }
  };

  const handleSub = (id: string) => {
    if (typeModal === "Important link") {
      const _link = importantLinks.filter((el) => el.id !== id);
      setImportantLinks(_link);
    } else if (typeModal === "Information") {
      const _information = information.filter((el) => el.id !== id);
      setInformation(_information);
    } else if (typeModal === "About us") {
      const _aboutUsItems = aboutUsItems.filter((el) => el.id !== id);
      setImportantLinks(_aboutUsItems);
    }
  };

  const onSubmitModal = async () => {
    setIsLoading(true);
    if (typeModal === "Link social") {
      setData((prev) => ({
        ...prev,
        footer: {
          ...prev.footer,
          social: linkSocial,
        },
      }));
    } else if (typeModal === "Important link") {
      setData((prev) => ({
        ...prev,
        footer: {
          ...prev.footer,
          importantLinks: importantLinks,
        },
      }));
    } else if (typeModal === "Information") {
      setData((prev) => ({
        ...prev,
        footer: {
          ...prev.footer,
          information: information,
        },
      }));
    } else if (typeModal === "About us") {
      setData((prev) => ({
        ...prev,
        footer: {
          ...prev.footer,
          aboutUs: {
            ...prev.footer.aboutUs,
            items: aboutUsItems,
          },
        },
      }));
    } else {
      const formData = new FormData();
      formData.append("folderPath", `landing-page/${params.symbol}`);

      formData.append("files", image?.file);
      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      listImg.forEach((el) =>
        setData((prev) => ({
          ...prev,
          footer: {
            ...prev.footer,
            logo: el.filePath,
          },
        }))
      );
    }
    setIsLoading(false);
    setIsModalUpload(false);
    setTypeModal("");
  };

  // Links navigate
  const openTagWindow = (url: string) => {
    window.open(url);
  };

  return (
    <>
      <div className="flex gap-x-8 max-md:flex-col gap-y-6">
        <div className="w-1/3 max-md:w-full flex md:flex-col gap-y-3 gap-x-2">
          <div
            className={`${
              isEdit && "border-1 border-dashed"
            } !w-auto !max-w-[50%] h-auto max-h-24 rounded`}
          >
            <UploadDocs
              imgClassName="!object-contain"
              data={data?.footer}
              isEdit={isEdit}
              openModal={isModalUpload}
              setOpenModal={setIsModalUpload}
              file={image?.file}
              setFile={setFile}
              onSubmit={onSubmitModal}
              srcImg={data?.footer?.logo}
              children={
                <div className="w-full h-full flex justify-center items-center gap-x-1">
                  <Icons iconName="upload" />
                  <span>Upload</span>
                </div>
              }
            />
          </div>
          <span
            contentEditable={isEdit}
            className="text-sm max-h-[120px] overflow-hidden max-md:hidden"
            onBlur={(e) => onBlurDocument(e, "footer", "desc")}
          >
            {data?.footer?.desc}
          </span>
          <div className="flex items-center gap-x-3 max-md:hidden">
            {data?.footer?.social?.map((el) => (
              <div
                className="w-10 h-10 rounded-full"
                style={isEdit ? { ...themesSetting.container } : {}}
                key={el.id}
              >
                {el.link && (
                  <img
                    className="w-full h-full rounded-full object-cover cursor-pointer hover:opacity-80"
                    src={el.logo}
                    alt=""
                    onClick={() => !isEdit && openTagWindow(el.link)}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-col w-[80%] gap-y-2 md:hidden">
            <span
              contentEditable={isEdit}
              className="text-sm max-h-[120px] overflow-hidden"
              onBlur={(e) => onBlurDocument(e, "footer", "desc")}
            >
              {data?.footer?.desc}
            </span>
            <div className="flex items-center gap-x-3">
              {data?.footer?.social?.map((el) => (
                <div
                  className="w-10 h-10 rounded-full"
                  style={isEdit ? { ...themesSetting.container } : {}}
                  key={el.id}
                >
                  {el.link && (
                    <img
                      className="w-full h-full rounded-full object-cover cursor-pointer hover:opacity-80"
                      src={el.logo}
                      alt=""
                      onClick={() => !isEdit && openTagWindow(el.link)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {isEdit && (
            <VButton type="outline" onClick={() => openModal("Link social")}>
              + Add link
            </VButton>
          )}
        </div>
        <div className="relative w-1/3 max-md:w-full flex flex-col items-center gap-y-3">
          {isEdit && (
            <div className="absolute top-0 right-0">
              <VButton
                className="w-8 h-8"
                radius="full"
                iconLeft={<Icons iconName="edit" />}
                onClick={() => openModal("Important link")}
              />
            </div>
          )}
          <span className="font-semibold mb-4">Important Link</span>
          <div className="flex flex-col max-md:items-center gap-y-2">
            {data?.footer?.importantLinks?.map((el) => (
              <span
                className="hover:opacity-80 cursor-pointer"
                onClick={() => !isEdit && openTagWindow(el.value)}
              >
                {el.label}
              </span>
            ))}
          </div>
        </div>
        <div className="relative w-1/3 max-md:w-full flex flex-col gap-y-3">
          {isEdit && (
            <div className="absolute top-0 right-0">
              <VButton
                className="w-8 h-8"
                radius="full"
                iconLeft={<Icons iconName="edit" />}
                onClick={() => openModal("Information")}
              />
            </div>
          )}
          <span className="font-semibold mb-4 text-center">Information</span>
          <div className="flex flex-col gap-y-2">
            {data?.footer?.information?.map((el) => (
              <div className="flex items-center gap-x-2">
                <span className="opacity-80">{el.label}:</span>
                <span className="font-semibold">{el.value}</span>
              </div>
            ))}
          </div>
          <img
            className="w-full object-cover"
            src="/assets/images/map.png"
            alt=""
          />
        </div>
      </div>
      <div className="w-[50vh] max-md:w-full flex flex-col gap-y-6">
        <span
          contentEditable={isEdit}
          onBlur={(e) => onBlurDocument(e, "footer", "title", "aboutUs")}
        >
          {data?.footer?.aboutUs?.title}
        </span>
        <div className="relative flex flex-col gap-y-3">
          {isEdit && (
            <div className="absolute top-0 right-0">
              <VButton
                className="w-8 h-8"
                radius="full"
                iconLeft={<Icons iconName="edit" />}
                onClick={() => openModal("About us")}
              />
            </div>
          )}
          {data?.footer?.aboutUs?.items?.map((it) => (
            <div className="flex gap-x-1">
              <span className="opacity-70">{it.label}:</span>
              <span>{it.value}</span>
            </div>
          ))}
        </div>
        <div className="flex items-center gap-x-1">
          <Icons iconName="copy_right" />
          <span
            contentEditable={isEdit}
            onBlur={(e) => onBlurDocument(e, "footer", "copyright", "aboutUs")}
          >
            {data?.footer?.aboutUs?.copyright}
          </span>
        </div>
      </div>

      {/* Modal */}
      <VModal
        loading={isLoading}
        title={typeModal}
        isOpen={typeModal !== ""}
        onConfirm={onSubmitModal}
        onCancel={() => setTypeModal("")}
      >
        {typeModal === "Link social" && (
          <div className="flex flex-col gap-y-2">
            {linkSocial?.map((el) => (
              <div className="w-full">
                <VInput
                  value={el?.link}
                  label={el?.name}
                  placeholder="Link..."
                  onChange={(e) =>
                    handleChangeInput("", e.target.value, el?.id)
                  }
                />
              </div>
            ))}
          </div>
        )}
        {typeModal === "Important link" && (
          <>
            <div className="flex flex-col gap-y-4">
              {importantLinks?.map((el) => (
                <div className="relative flex items-center gap-x-2">
                  <div className="absolute top-0 right-0">
                    <VButton
                      className="w-6 h-6"
                      onClick={() => handleSub(el.id)}
                    >
                      -
                    </VButton>
                  </div>
                  <VInput
                    label="Label"
                    value={el.label}
                    onChange={(e) =>
                      handleChangeInput("label", e.target.value, el.id)
                    }
                  />
                  <VInput
                    label="Value"
                    value={el.value}
                    onChange={(e) =>
                      handleChangeInput("value", e.target.value, el.id)
                    }
                  />
                </div>
              ))}
              <div className="w-full flex justify-center">
                <VButton className="w-8 h-8" radius="full" onClick={handleAdd}>
                  +
                </VButton>
              </div>
            </div>
          </>
        )}
        {typeModal === "Information" && (
          <>
            <div className="flex flex-col gap-y-4">
              {information?.map((el) => (
                <div className="relative flex items-center gap-x-2">
                  <div className="absolute top-0 right-0">
                    <VButton
                      className="w-6 h-6"
                      onClick={() => handleSub(el.id)}
                    >
                      -
                    </VButton>
                  </div>
                  <VInput
                    label="Label"
                    value={el.label}
                    onChange={(e) =>
                      handleChangeInput("label", e.target.value, el.id)
                    }
                  />
                  <VInput
                    label="Value"
                    value={el.value}
                    onChange={(e) =>
                      handleChangeInput("value", e.target.value, el.id)
                    }
                  />
                </div>
              ))}
              <div className="w-full flex justify-center">
                <VButton className="w-8 h-8" radius="full" onClick={handleAdd}>
                  +
                </VButton>
              </div>
            </div>
          </>
        )}
        {typeModal === "About us" && (
          <>
            <div className="flex flex-col gap-y-4">
              {aboutUsItems?.map((el) => (
                <div className="relative flex items-center gap-x-2">
                  <div className="absolute top-0 right-0">
                    <VButton
                      className="w-6 h-6"
                      onClick={() => handleSub(el.id)}
                    >
                      -
                    </VButton>
                  </div>
                  <VInput
                    label="Label"
                    value={el.label}
                    onChange={(e) =>
                      handleChangeInput("label", e.target.value, el.id)
                    }
                  />
                  <VInput
                    label="Value"
                    value={el.value}
                    onChange={(e) =>
                      handleChangeInput("value", e.target.value, el.id)
                    }
                  />
                </div>
              ))}
              <div className="w-full flex justify-center">
                <VButton className="w-8 h-8" radius="full" onClick={handleAdd}>
                  +
                </VButton>
              </div>
            </div>
          </>
        )}
      </VModal>
    </>
  );
};

export default Footer;
