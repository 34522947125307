import Icons from "helper/icons";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import APIs from "api";
import VButton from "components/v-core/v-button";
import VModal from "components/v-core/v-modal";
import { v4 as uuidv4 } from "uuid";
import UploadItems from "../upload/UploadItems";

type IProps = {
  data?: any;
  isEdit?: boolean;
  index?: number;
  required?: any;
  style?: any;
  setData?: (e: any) => void;
  handleMoveSection?: (type: any, index: number) => void;
  checkRequiredSection?: (state: any, key: string) => any;
};

const Documents = (props: IProps) => {
  const {
    data,
    setData,
    isEdit,
    index,
    handleMoveSection,
    required,
    checkRequiredSection,
    style,
  } = props;

  const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [partnersData, setPartnersData] = useState(null);
  const [partnerSelected, setPartnerSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getDataDefault = () => {
    return {
      id: uuidv4(),
      title: "Hoang",
      desc: "",
      items: [
        {
          id: uuidv4(),
          src: "",
          isNew: true,
        },
      ],
    };
  };

  const onOpenModal = (data: any) => {
    setPartnerSelected(data);
    setPartnersData(data?.items);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setData((prev) => ({
      ...prev,
      partners: prev.partners?.concat(getDataDefault()),
    }));
  };

  const handleSub = (item: any) => {
    const _data = data.partners?.filter((el) => el.id !== item.id);
    setData((prev) => ({
      ...prev,
      partners: [..._data],
    }));
  };

  const onBlurDocument = (e: any, name: string, uuid?: string) => {
    setData((prev) => ({
      ...prev,
      partners: prev.partners.map((el) =>
        el.id === uuid
          ? {
              ...el,
              [name]: e.target.innerText,
            }
          : { ...el }
      ),
    }));
  };

  const onSubmitModal = async () => {
    if (checkRequiredSection(partnersData, "partners")) {
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("folderPath", `landing-page/${params.symbol}`);

    const statesNew = partnersData
      .filter((st) => st.file)
      .map((stNew) => {
        formData.append("files", stNew.file);
        return stNew;
      });
    if (!statesNew) {
      setIsLoading(false);
    }

    const listImg: any = await APIs.UPLOAD.uploadMultiFile({
      body: formData,
    });

    listImg.forEach((el) => {
      statesNew[el.id] = {
        ...statesNew[el.id],
        src: el?.filePath,
        isNew: false,
      };
    });

    setData((prev) => ({
      ...prev,
      partners: prev?.partners?.map((partner) =>
        partner.id === partnerSelected.id
          ? {
              ...partnerSelected,

              items: partnersData?.map((it) => {
                const _stateFound = statesNew.find((c) => c.id === it.id);
                if (_stateFound) {
                  return {
                    ...it,
                    ..._stateFound,
                  };
                }
                return it;
              }),
            }
          : { ...partner }
      ),
    }));
    setIsLoading(false);
    setOpenModal(false);
  };

  // Links navigate
  const openTagWindow = (url: string) => {
    window.open(url);
  };

  return (
    <>
      <div
        className="relative w-full flex flex-col items-center gap-y-5"
        style={style}
      >
        {isEdit && (
          <div className="absolute top-2 right-3 flex gap-x-3">
            <VButton
              className="w-8 h-8"
              radius="full"
              iconLeft={<Icons iconName="arrow-up" />}
              onClick={() => handleMoveSection("up", index)}
            />
            <VButton
              className="w-8 h-8"
              radius="full"
              iconLeft={<Icons iconName="arrow-down" />}
              onClick={() => handleMoveSection("down", index)}
            />
          </div>
        )}
        <div className="flex md:flex-row flex-col w-full gap-x-4 justify-center">
          {data?.partners?.map((partner) => (
            <div className="flex flex-col items-center justify-center bg-[#171E2E] p-2 rounded-md">
              <div className="flex flex-col items-center justify-center max-w-[50vh]">
                <span
                  className="text-xl font-bold text-center"
                  contentEditable={isEdit}
                  onBlur={(e) => onBlurDocument(e, "title", partner.id)}
                >
                  {partner.title}
                  {/* Our partners */}
                </span>
                <div className="w-10 border-b-2 m-0" />
              </div>
              <div
                className="relative w-full flex flex-wrap justify-center gap-5"
                key={partner.id}
              >
                {partner &&
                  partner.items?.map((it) => (
                    <div
                      key={it.id}
                      className=" flex flex-col items-center gap-y-1 cursor-pointer"
                      onClick={() => !isEdit && openTagWindow(it.link)}
                    >
                      {it.src ? (
                        <img
                          className={`${
                            isEdit
                              ? "w-32 h-30 border rounded"
                              : "w-30 h-30 rounded-full"
                          }  object-cover`}
                          src={it.src}
                          alt=""
                        />
                      ) : (
                        <div
                          className={`${
                            isEdit
                              ? "w-32 h-30 border rounded"
                              : "w-30 h-30 rounded-full"
                          } flex justify-center items-center`}
                        >
                          {isEdit && (
                            <Icons iconName="add_image" className="p-2" />
                          )}
                        </div>
                      )}
                      <span className="text-base font-semibold">
                        {it.title}
                      </span>
                      <span className="opacity-70">{it.desc}</span>
                    </div>
                  ))}
                {isEdit && (
                  <div className="absolute right-0 -top-10 flex gap-x-2">
                    <VButton
                      className="w-8 h-8"
                      radius="full"
                      onClick={() => handleSub(partner)}
                    >
                      <span className="text-red-500 text-xl font-bold">-</span>
                    </VButton>
                    <VButton
                      className="w-8 h-8"
                      radius="full"
                      iconLeft={<Icons iconName="edit" />}
                      onClick={() => onOpenModal(partner)}
                    />
                    <VButton
                      className="w-8 h-8"
                      radius="full"
                      onClick={handleAdd}
                    >
                      +
                    </VButton>
                  </div>
                )}
              </div>
            </div>
          ))}
          {data?.partners?.map((partner) => (
            <div className="flex flex-col items-center justify-center bg-[#171E2E] p-2 rounded-md">
              <div className="flex flex-col items-center justify-center max-w-[50vh]">
                <span
                  className="text-xl font-bold text-center"
                  contentEditable={isEdit}
                  onBlur={(e) => onBlurDocument(e, "title", partner.id)}
                >
                  {/* {partner.title} */}
                  Media partners
                </span>
                <div className="w-10 border-b-2 m-0" />
              </div>
              <div
                className="relative w-full flex flex-wrap justify-center gap-5"
                key={partner.id}
              >
                {partner &&
                  partner.items?.map((it) => (
                    <div
                      key={it.id}
                      className=" flex flex-col items-center gap-y-1 cursor-pointer"
                      onClick={() => !isEdit && openTagWindow(it.link)}
                    >
                      {it.src ? (
                        <img
                          className={`${
                            isEdit
                              ? "w-32 h-30 border rounded"
                              : "w-30 h-30 rounded-full"
                          }  object-cover`}
                          src={it.src}
                          alt=""
                        />
                      ) : (
                        <div
                          className={`${
                            isEdit
                              ? "w-32 h-30 border rounded"
                              : "w-30 h-30 rounded-full"
                          } flex justify-center items-center`}
                        >
                          {isEdit && (
                            <Icons iconName="add_image" className="p-2" />
                          )}
                        </div>
                      )}
                      <span className="text-base font-semibold">
                        {it.title}
                      </span>
                      <span className="opacity-70">{it.desc}</span>
                    </div>
                  ))}
                {isEdit && (
                  <div className="absolute right-0 -top-10 flex gap-x-2">
                    <VButton
                      className="w-8 h-8"
                      radius="full"
                      onClick={() => handleSub(partner)}
                    >
                      <span className="text-red-500 text-xl font-bold">-</span>
                    </VButton>
                    <VButton
                      className="w-8 h-8"
                      radius="full"
                      iconLeft={<Icons iconName="edit" />}
                      onClick={() => onOpenModal(partner)}
                    />
                    <VButton
                      className="w-8 h-8"
                      radius="full"
                      onClick={handleAdd}
                    >
                      +
                    </VButton>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <VModal
          title={`Upload`}
          isOpen={openModal}
          onCancel={() => setOpenModal(false)}
          onConfirm={onSubmitModal}
          loading={isLoading}
        >
          <UploadItems
            data={partnersData}
            setData={setPartnersData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            type="partners"
            required={required}
          />
        </VModal>
      </div>
    </>
  );
};

export default Documents;
