import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getAll = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/categories?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getByGroup = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/categories/get-by-group?${qs.stringify(req.params)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CategoriesService = {
  getAll,
  getByGroup,
};

export default CategoriesService;
