import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { convertS3Link } from "api/s3-request";

type IProps = {
  data?: any;
  type?: string;
  isEdit?: boolean;
};

const CardItem = (props: IProps) => {
  const { data, type, isEdit } = props;
  const [parentSize, setParentSize] = useState({ width: 0, height: 0 });
  const parentRef = useRef(null);

  useEffect(() => {
    if (parentRef && parentRef.current) {
      const updateSize = () => {
        const rect = parentRef.current.getBoundingClientRect();
        setParentSize({ width: rect.width, height: rect.height });
      };

      updateSize();

      const observer = new ResizeObserver(() => updateSize());
      observer.observe(parentRef.current);

      return () => observer.disconnect();
    }
  }, [parentRef]);

  return (
    <div className="w-full flex justify-center">
      <div
        ref={parentRef}
        className="w-[486px] h-[306px] max-md:w-[243px] max-md:h-[153px] relative rounded-[16px]"
      >
        <img
          src={convertS3Link(data[type]?.background)}
          alt={`bank name`}
          className="w-full h-full rounded-[16px] object-cover absolute top-0 left-0 pointer-events-none"
        />
        <Draggable
          bounds="parent"
          position={{
            x: (data?.[type]?.logo?.position.x / 486) * parentSize.width,
            y: (data?.[type]?.logo?.position.y / 306) * parentSize.height,
          }}
          disabled={true}
        >
          <div className="w-[2rem] max-md:w-[1rem] h-[2rem] max-md:h-[1rem] absolute">
            <img
              className="max-w-full max-h-full rounded object-cover"
              src={data?.[type]?.logo?.src}
              alt="logo"
            />
          </div>
        </Draggable>
        {data[type]?.userInputs?.map((input, index) => (
          <Draggable
            key={input.id}
            bounds="parent"
            disabled={true}
            // defaultPosition={input?.position}
            position={{
              x: (input.position.x / 486) * parentSize.width,
              y: (input.position.y / 306) * parentSize.height,
            }}
          >
            <div
              contentEditable={isEdit}
              style={{
                fontSize: `${(input.font_size / 486) * parentSize.width}px`,
                color: `${input?.color}`,
                fontWeight: `${input?.font_weight}`,
                display: "inline-block",
                border: "none",
                position: "absolute",
                zIndex: index + 1,
                background: `linear-gradient(90deg, ${input?.color}, ${input?.color})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
              }}
              // onInput={(e) =>
              //   handleChangeField(index)(
              //     "placeholder",
              //     e.currentTarget.textContent || "",
              //     "front"
              //   )
              // }
            >
              {input.value || ""}
            </div>
          </Draggable>
        ))}
      </div>
    </div>
  );
};

export default CardItem;
