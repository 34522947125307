import APIs from "api/events";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { format } from "date-fns";
import VButton from "components/v-core/v-button";

const Events_my = () => {
  const [data, setData] = useState([]);
  const [toggleStates, setToggleStates] = useState<Record<string, boolean>>({});
  const history = useHistory();
  const params: any = useParams();
  const event = params.event;

  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = async () => {
    try {
      const res: any = await APIs.getEventByUserId();
      if (Array.isArray(res)) {
        setData(res);
        const initialStates = res.reduce(
          (acc: Record<string, boolean>, item: any) => {
            acc[item._id] = item.status === "approved";
            return acc;
          },
          {}
        );
        setToggleStates(initialStates);
      } else {
        console.error("Invalid response format:", res);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleToggle = async (id: string, item: any) => {
    const newStatus = item.status === "approved" ? "declined" : "approved";
    try {
      await APIs.updateStatusEvent({
        body: { status: newStatus },
        params: { id },
      });
      setData((prevData) =>
        prevData.map((item) =>
          item._id === id ? { ...item, status: newStatus } : item
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleView = (data: any) => {
    return history.push(`/smart/${params.event}/${data._id}`);
  };

  const handleEdit = (data: any) => {
    const token = localStorage.getItem("auth");
    history.push(
      `/smart/${params.event}/${data._id}?isEdit=true&token=${token}`
    );
  };

  const dataFilter = useMemo(() => {
    return data.filter((el: any) => el.event.eventType === event);
  }, [event, data]);

  return (
    <div className="p-4 bg-black-2 h-screen">
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-white">
          <thead className="text-sm bg-[#212529]">
            <tr>
              <th scope="col" className="px-[15px] py-3">
                Name
              </th>
              <th scope="col" className="px-[15px] py-3">
                Banner
              </th>
              <th scope="col" className="px-[15px] py-3">
                Vip
              </th>
              <th scope="col" className="px-[15px] py-3">
                Status
              </th>
              <th scope="col" className="px-[15px] py-3 whitespace-nowrap">
                On/Off Status
              </th>
              <th scope="col" className="px-[15px] py-3">
                Created At
              </th>
              <th scope="col" className="px-[15px] py-3">
                Add Managers
              </th>
              <th scope="col" className="px-[15px] py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="pt-1 bg-[#212529]">
            {dataFilter.map((item: any, index: number) => (
              <tr className="border-t" key={item._id || index}>
                <td className="px-[15px] py-[15px] text-sm text-center">
                  {item?.event?.profit?.title}
                </td>
                <td className="px-[15px] py-[15px] text-sm text-center">
                  {item?.event?.banner?.type}
                </td>
                <td className="px-[15px] py-[15px] text-sm text-center">
                  Normal
                </td>
                <td
                  className={`px-[15px] py-[15px] text-sm text-red-500 ${
                    item?.status === "approved" ? " !text-green-500" : ""
                  } `}
                >
                  {item?.status}
                </td>
                <td className="px-[15px] py-[15px] text-center">
                  <button
                    onClick={() => handleToggle(item?._id, item)}
                    className={`w-14 h-6 flex items-center rounded-full transition-colors duration-300 ${
                      item?.status === "approved"
                        ? "bg-green-500 "
                        : "bg-gray-300"
                    }`}
                  >
                    <div
                      className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                        item?.status === "approved"
                          ? "translate-x-8"
                          : "translate-x-0"
                      }`}
                    ></div>
                  </button>
                </td>
                <td className="px-[15px] py-[15px] text-sm text-center">
                  {item?.createdAt
                    ? format(new Date(item.createdAt), "dd/MM/yyyy, HH:mm:ss")
                    : "N/A"}
                </td>
                <td className="px-[15px] py-[15px] whitespace-normal">
                  <VButton className="!text-[14px]">Add Managers</VButton>
                </td>
                <td className="px-[15px] py-[15px] flex flex-wrap gap-1">
                  <VButton onClick={() => handleView(item)}>View</VButton>
                  <VButton onClick={() => handleEdit(item)}>Edit</VButton>
                  <VButton disabled={true}>Register Items</VButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Events_my;
