import VButton from "components/v-core/v-button";
import {
  exportComponentAsJPEG,
  exportComponentAsPNG,
} from "react-component-export-image";
import { IoMdDownload } from "react-icons/io";

const DownloadImg = ({ imgRef, fileName }) => {
  return (
    <>
      <div className=" max-w-[600px] flex gap-x-2 mb-5">
        <VButton
          radius="full"
          className=" w-1/2 border-none rounded-full py-2 flex justify-center items-center gap-2"
          onClick={() =>
            exportComponentAsPNG(imgRef, {
              fileName:
                `${fileName}_vzonex_${new Date().getTime()}` || "vzonex",
            })
          }
        >
          <IoMdDownload />
          Download PNG
        </VButton>
        <VButton
          radius="full"
          className=" w-1/2 rounded-full py-2 flex justify-center items-center gap-2"
          onClick={() =>
            exportComponentAsJPEG(imgRef, {
              fileName:
                `${fileName}_vzonex_${new Date().getTime()}` || "vzonex",
            })
          }
        >
          <IoMdDownload />
          Download JPEG
        </VButton>
      </div>
    </>
  );
};

export default DownloadImg;
