import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.theme.colors["black100"]};
  padding: 15px;
  border-radius: 8px;
  img {
    cursor: pointer;
  }
`;

export const Header = styled.div`
  display: flex;
  color: black;
`;

export const Left = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #e9ebf6;
  height: 41px;
  padding-right: 15px;
`;

export const ItemLeft = styled.div`
  padding: 7px 12px;
  background: #f8f8fd;
  cursor: pointer;
  border-radius: 8px;
  span:not(:first-child) {
    margin-left: 3px;
    font-size: 18px;
  }
  &:hover {
    color: #1166e3;
    background-color: #eff3fe;
  }
`;

export const Right = styled.div`
  margin-left: 15px;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  width: 100%;
  overflow-y: hidden;
  div {
    width: fit-content;
    margin-right: 10px;
    padding: 7px 12px;
    background: #f8f8fd;
    cursor: pointer;
    border-radius: 8px;
    max-height: 41px;
    &:hover {
      color: #1166e3;
      background-color: #eff3fe;
    }
  }
`;

export const Item = styled.div`
  border-radius: 10px;
  border: 1px solid white;
  width: 250px;
  margin: 15px;
  overflow: hidden;
  position: relative;
  &:hover {
    opacity: 0.85;
  }
  span {
    font-weight: 500;
  }
  p {
    color: #ff754c;
    margin: 10px 0;
    font-weight: bold;
  }
  label {
    font-size: 14px;
    opacity: 0.5;
  }
  @media screen and (max-width: 500px) {
    width: 175px;
    margin: 5px 3px;
  }
  @media screen and (max-width: 425px) {
    width: 250px;
    margin: 3px 3px;
  }
`;

export const Content = styled.div`
  padding: 0 15px 10px 15px;
  background: ${(props) => props.theme.colors["bgColorLayer1"]};
  .name {
    padding-top: 2px;
  }
  .gift {
    display: inline-block;
    padding-top: 0;
  }
  button {
    position: absolute;
    bottom: 8px;
    right: 4px;
    background: linear-gradient(
      89.86deg,
      rgb(10 17 27) 4.51%,
      rgb(115, 58, 188) 99.94%
    );
    color: white;
    font-weight: 600;
    font-size: 12px;
    border-radius: 10px;
  }
`;

export const Image = styled.div`
  text-align: center;
  background: white;
  img {
    max-width: 100%;
    height: 140px;
    object-fit: cover;
  }
`;

export const Campaign = styled.div`
  margin-top: 10px;
  border-radius: 6px;
  width: fit-content;
  span {
    padding: 3px;
    font-size: 12px;
  }
`;
export const ContainItem = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const Received = styled.div``;

export const CounterdownWrapper = styled.div`
  .title {
    font-size: 14px;
    text-align: center;
    font-weight: 700;
  }
  .Upcomming {
    color: ${(props) => props.theme.colors["yellow100"]};
  }
  .Happenning {
    color: ${(props) => props.theme.colors["green100"]};
  }
  .countdown-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    .countdown-item {
      margin-right: 3px;
      .countdown-item-label {
        margin-bottom: 2px;
        padding-top: 0px;
        font-size: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
      .countdown-item-value {
        background: #284f8a;
        padding: 0;
        font-family: "Poppins", Sans-serif;
        font-size: 14px;
        border-radius: 12px 12px 12px 12px;
        width: 30px;
        height: 30px;
        margin-bottom: 0px;
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  min-width: 150px;
  text-align: left;
  z-index: 1000000;
  overflow: hidden;
  max-width: 100%;
  hr {
    margin: 0;
    width: 100%;
  }
`;
