import { Disclosure } from "@headlessui/react";
import { ROUTES } from "config/routes";
import { MasterContext } from "context/MasterContext";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type IProps = {
  openMenu?: boolean;
  setOpenMenu?: (value: any) => void;
};

const Menus = ({ openMenu, setOpenMenu }: IProps) => {
  const { menus } = useContext(MasterContext);
  const { themesSetting, theme } = useContext(ThemeContext);
  const history = useHistory();
  const [selectedMenu, setSelectedMenu] = useState(history.location.pathname);

  const [subMenuSelected, setSubMenuSelected] = useState(null);

  useEffect(() => {
    if (menus) {
      menus.map((menu) => {
        if (menu.children) {
          const _sub = menu.children.find((el) => el.url === selectedMenu);
          if (_sub) {
            setSubMenuSelected(_sub);
          }
        }
      });
    }
  }, [menus]);

  const clickMenu = (type?: string, menu?: any) => () => {
    setSelectedMenu(menu.url);

    if (menu.is_coming_soon) {
      if (isMobile) {
        setOpenMenu(false);
      }
      return history.push(`${ROUTES.MY_COMING_SOON}?key=${menu._id}`);
    }
    if (menu.is_pdf) {
      if (isMobile) {
        setOpenMenu(false);
      }
      return history.push(`${ROUTES.MY_PDF}?key=${menu._id}`);
    }
    if (menu.type_view === "webview") {
      return history.push(`${ROUTES.MY_WEBVIEW}?key=${menu._id}`);
    }
    if (menu.type_view === "external") {
      if (menu.url.includes("/create")) {
        const token = localStorage.getItem("auth");
        window.open(`${menu.url}?token=${token}`);
      } else {
        window.open(menu.url);
      }
    } else {
      const validRoute = [
        "/smart/events",
        "/smart/schools",
        "/smart/charity",
        "/smart/hospitals",
        "/smart/polls",
      ];
      if (validRoute.some((i) => menu.url.includes(i))) {
        const token = localStorage.getItem("auth");
        window.open(`${menu.url}?isEdit=true&token=${token}`);
      } else history.push(menu.url);
    }
    if (isMobile) {
      setOpenMenu(false);
    }
    if (type === "menu") {
      setSubMenuSelected(null);
    } else {
      setSubMenuSelected(menu);
    }
  };

  // Colors
  const hoverColor = themesSetting?.menus?.items?.menuHover?.color;
  const hoverClass = useMemo(() => {
    if (hoverColor === "v-yellow") return "hover:!text-v-yellow";
    if (hoverColor === "v-blue300") return "hover:!text-v-blue300";
    if (hoverColor === "v-blue") return "hover:!text-v-blue";
    if (hoverColor === "v-orange") return "hover:!text-v-orange";
    if (hoverColor === "v-purple") return "hover:!text-v-purple";
    if (hoverColor === "v-red") return "hover:!text-v-red";
    if (hoverColor === "v-orange") return "hover:!text-v-orange";

    return "hover:!text-v-white";
  }, [hoverColor]);

  return (
    <>
      <div
        className={`flex flex-col gap-y-2 overflow-y-auto no-scrollbar ${
          isMobile && "h-[calc(100%-130px)]"
        } `}
      >
        {menus?.map((menu: any, idx: number) => {
          return menu?.children && menu.children.length > 0 ? (
            <Disclosure
              key={idx}
              defaultOpen={menu.is_open_menu}
              as="div"
              className="flex flex-col"
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      theme === "dark"
                        ? subMenuSelected?.parent_id === menu._id
                          ? "hover:!text-v-white"
                          : "hover:!text-v-white"
                        : subMenuSelected?.parent_id === menu._id
                        ? `${hoverClass}`
                        : "hover:!text-current",
                      !openMenu ? "justify-center" : " justify-between",
                      "relative flex items-center w-full text-left rounded-md py-2 gap-x-3 text-sm leading-6 font-semibold transition-colors duration-300 ease-linear"
                    )}
                    style={
                      subMenuSelected?.parent_id === menu._id
                        ? themesSetting?.menus?.items?.menuActive
                        : themesSetting?.menus?.items
                    }
                  >
                    <div className="flex gap-x-2">
                      {menu?.img_icon ? (
                        <img
                          src={menu?.img_icon}
                          alt="menu-icon"
                          className="w-5 h-5 object-cover"
                        />
                      ) : (
                        <Icons iconName={menu?.keyVal} />
                      )}
                      {openMenu && menu.name}
                    </div>
                    {openMenu && (
                      <Icons
                        iconName="dropdown"
                        className={`absolute right-1 transition-transform duration-300 ${
                          open ? "-rotate-180" : ""
                        }`}
                      />
                    )}
                  </Disclosure.Button>
                  <div
                    className={`transition-all duration-500 ease-linear overflow-hidden`}
                    style={
                      open
                        ? {
                            height: menu.children?.length * 40,
                          }
                        : { height: 0 }
                    }
                  >
                    <Disclosure.Panel as="ul" className={`mt-1 px-2`}>
                      {menu.children.map((subItem) => (
                        <li key={subItem.name}>
                          <div
                            className={classNames(
                              theme === "dark"
                                ? subItem.url === selectedMenu
                                  ? ""
                                  : "hover:!text-v-white"
                                : subItem.url === selectedMenu
                                ? ""
                                : `${hoverClass}`,
                              openMenu ? "pl-5 pr-2" : "pl-0 justify-center",
                              "flex items-center gap-x-2 rounded-md py-2 text-sm leading-6 cursor-pointer transition-all duration-300 ease-linear"
                            )}
                            style={
                              subItem.url === selectedMenu
                                ? themesSetting?.menus?.items?.subMenuActive
                                : themesSetting?.menus?.items
                            }
                            onClick={clickMenu("subMenu", subItem)}
                          >
                            {subItem?.img_icon ? (
                              <img
                                src={subItem?.img_icon}
                                alt="menu-icon"
                                className="w-5 h-5 object-cover"
                              />
                            ) : (
                              <Icons
                                isActive={subItem.url === selectedMenu}
                                iconName={subItem?.keyVal}
                              />
                            )}
                            {openMenu && subItem.name}
                          </div>
                        </li>
                      ))}
                    </Disclosure.Panel>
                  </div>
                </>
              )}
            </Disclosure>
          ) : (
            <div
              key={menu.url}
              {...{
                selected: menu.url === selectedMenu,
              }}
              onClick={clickMenu("menu", menu)}
              className={`${
                selectedMenu === menu.url ? "" : `${hoverClass}`
              } flex items-center ${
                !openMenu && "justify-center"
              } gap-x-2 py-2 px-1 rounded-[4px] cursor-pointer uppercase text-sm font-semibold pb-2 transition-colors duration-300 ease-linear`}
              style={
                menu.url === selectedMenu
                  ? themesSetting?.menus?.items?.subMenuActive
                  : themesSetting?.menus?.items
              }
            >
              {menu?.img_icon ? (
                <img
                  src={menu.img_icon}
                  alt="menu-icon"
                  className="w-5 h-5 object-cover"
                />
              ) : (
                <Icons
                  isActive={menu.url === selectedMenu}
                  iconName={menu?.keyVal}
                />
              )}
              {openMenu && menu.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Menus;
