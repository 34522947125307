import { Fragment, useContext, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ThemeContext } from "context/ThemeContext";
import VButton from "components/v-core/v-button";

type Props = {
  isOpen?: any;
  title?: any;
  onCancel?: any;
  onConfirm?: any;
  cancelLabel?: any;
  confirmLabel?: any;
  loading?: any;
  centered?: boolean;
  fullscreen?: boolean;
  showImage?: boolean;
  children?: any;
  disableConfirm?: boolean;
  element?: any;
  onDelete?: any;
  deleteLabel?: any;
  tabs?: any;
  onChangeTab?: any;
  isPaginate?: boolean;
  lazyParams?: any;
  onChangeParams?: any;
  totalPages?: number;
  activeTab?: string;
};

const VModal = ({ children, ...props }: Props) => {
  const {
    isOpen,
    title,
    onCancel,
    disableConfirm,
    onConfirm,
    cancelLabel,
    confirmLabel,
    loading = false,
    centered = false,
    fullscreen = false,
    element,
    onDelete,
    deleteLabel,
  } = props;

  const cancelButtonRef = useRef(null);
  const { themesSetting } = useContext(ThemeContext);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={(_) => onCancel && onCancel()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className={`fixed inset-0 z-10 w-screen overflow-y-auto 
          ${centered === true ? " mx-auto" : ""}
          ${fullscreen === true ? "w-full h-full" : ""}
          `}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="flex min-h-full justify-center p-4 items-center sm:p-0">
              <Dialog.Panel
                className="absolute-center max-h-[calc(100%-110px)] justify-center p-4 items-center sm:p-0 transform rounded-lg shadow-xl transition-all w-full sm:max-w-xl border-2"
                style={themesSetting.modal}
              >
                <div className="flex flex-col text-center sm:text-left mb-2">
                  {title && <div className="text-xl font-bold">{title}</div>}
                </div>
                <div className="max-h-[calc(100vh-300px)] overflow-y-auto">
                  {children}
                </div>

                <div
                  className={`w-full justify-center flex gap-2 border-t-[1px] border-dashed pt-4 mt-4`}
                  style={{ borderColor: themesSetting.layout.textContent }}
                >
                  {onCancel && (
                    <VButton
                      className="w-20"
                      type="outline"
                      disabled={loading}
                      loading={loading}
                      radius="full"
                      onClick={() => onCancel()}
                    >
                      {cancelLabel || "Close"}
                    </VButton>
                  )}
                  {onDelete && (
                    <VButton
                      className="w-20"
                      type="outline"
                      disabled={loading}
                      loading={loading}
                      radius="full"
                      onClick={() => onDelete()}
                    >
                      {deleteLabel || "Delete"}
                    </VButton>
                  )}
                  {onConfirm && (
                    <VButton
                      className="w-20"
                      disabled={disableConfirm || loading}
                      radius="full"
                      loading={loading}
                      onClick={() => onConfirm()}
                    >
                      {confirmLabel || "Confirm"}
                    </VButton>
                  )}
                  {element && element}
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default VModal;
