import { useContext, useEffect, useState } from "react";
import APIs from "api/events";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { MasterContext } from "context/MasterContext";
import { useLocation } from "react-router-dom";

const tabs = [
  { id: 1, name: "Tất cả" },
  { id: 2, name: "Sắp diễn ra" },
  { id: 3, name: "Đang diễn ra" },
  { id: 4, name: "Đã kết thúc" },
];

const Events = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);
  const [selectedImage, setSelectedImage] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { categories, getCategories } = useContext(MasterContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = async () => {
    try {
      const res: any = await APIs.getEventByUserId();
      setData(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    filterEventsByTab();
  }, [selectedTab, data]);

  const filterEventsByTab = () => {
    const now = new Date();

    const filtered = data.filter((item: any) => {
      const startDate = new Date(item?.event?.profit?.startDate);
      const endDate = new Date(item?.event?.profit?.endDate);

      if (selectedTab === 1) {
        return true;
      } else if (selectedTab === 2) {
        return now < startDate;
      } else if (selectedTab === 3) {
        return now >= startDate && now <= endDate;
      } else if (selectedTab === 4) {
        return now > endDate;
      }
      return true;
    });

    setFilteredData(filtered);
  };

  const handleView = (data: any) => {
    return history.push(`/smart/events/${data._id}`);
  };

  const getEventStatus = (startDate: string, endDate: string) => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (now < start) {
      return "Sắp diễn ra";
    } else if (now >= start && now <= end) {
      return "Đang diễn ra";
    } else {
      return "Đã kết thúc";
    }
  };

  // eventSmart
  useEffect(() => {
    getCategories({
      params: { group: "smart_events_type" },
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0) {
      setSelectedImage(categories[0]._id);
    }
  }, [categories]);

  const handleImageClick = (item: any) => {
    setSelectedImage(item._id);
    history.push(`/smart/${item.key}`);
  };

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0) {
      const currentPath = location.pathname.split("/").pop();
      const matchedCategory = categories.find(
        (item) => item.key === currentPath
      );
      if (matchedCategory) {
        setSelectedImage(matchedCategory._id);
      }
    }
  }, [location.pathname, categories]);

  return (
    <div className="p-4 bg-black-2 h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
        <div className="gap-3 flex justify-center">
          {tabs.map((item) => (
            <button
              key={item.id}
              onClick={() => setSelectedTab(item.id)}
              className={`h-6 text-xs rounded-lg px-2 whitespace-nowrap ${
                selectedTab === item.id
                  ? "bg-white text-yellow-500"
                  : "opacity-60 bg-white text-[#3A3F51]"
              }`}
            >
              {item.name}
            </button>
          ))}
        </div>
        <div className="gap-3 flex justify-center items-center">
          {Array.isArray(categories) &&
            categories.map((item) => (
              <img
                key={item.key}
                onClick={() => handleImageClick(item)}
                className={`transition-all duration-300 hover:h-[80px] hover:w-[80px] hover:opacity-100 cursor-pointer ${
                  selectedImage === item._id
                    ? "h-[80px] w-[80px] opacity-100"
                    : "h-[50px] w-[50px] opacity-60"
                }`}
                src={item.icon}
                alt=""
              />
            ))}
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-4 mt-4 overflow-y-scroll h-[calc(100vh-150px)]">
        {filteredData.map((item: any, index: number) => {
          const eventStatus = getEventStatus(
            item?.event?.profit?.startDate,
            item?.event?.profit?.endDate
          );
          const isEnded = eventStatus === "Đã kết thúc";

          return (
            <div
              onClick={() => handleView(item)}
              key={index}
              className="mt-2 flex justify-between h-[510px] max-w-[400px] flex-col w-full border rounded-[10px] hover:border hover:shadow-lg hover:shadow-yellow-500 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-[1.01] duration-200"
            >
              <div className="w-full max-h-[232px] flex justify-center items-center mb-3">
                <img
                  className="w-auto h-full rounded-t-[10px] cursor-pointer "
                  src={item?.event?.logo}
                  alt=""
                />
              </div>
              <div className="px-[15px] py-[10px]">
                <div className="">
                  <p className="text-center text-lg font-bold text-[#FF9900] cursor-pointer ">
                    {item?.event?.profit?.title}
                  </p>
                  <p
                    className={`text-center text-xl cursor-pointer text-gray-4 ${
                      eventStatus === "Đang diễn ra" ? "!text-[#00AC11]" : ""
                    }`}
                  >
                    {eventStatus}
                  </p>
                </div>
                <div className="grid grid-cols-6 gap-2 p-2 items-center text-center text-white mb-5">
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.startDate
                          ? format(
                              new Date(item?.event?.profit?.startDate),
                              "dd"
                            )
                          : "N/A"
                      }
                      className="text-center w-10 bg-[#243959] text-white rounded"
                      readOnly
                    />
                    <span className="text-xs mt-1">Ngày</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.startDate
                          ? format(
                              new Date(item?.event?.profit?.startDate),
                              "MM"
                            )
                          : "N/A"
                      }
                      className="text-center w-10 bg-[#243959] text-white rounded"
                      readOnly
                    />
                    <span className="text-xs mt-1">Tháng</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.startDate
                          ? format(
                              new Date(item?.event?.profit?.startDate),
                              "yyyy"
                            )
                          : "N/A"
                      }
                      className="text-center w-12 bg-[#243959] text-white rounded"
                      readOnly
                    />
                    <span className="text-xs mt-1">Năm</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.startDate
                          ? format(
                              new Date(item?.event?.profit?.startDate),
                              "HH"
                            )
                          : "N/A"
                      }
                      className="text-center w-10 bg-[#243959] text-white rounded"
                      readOnly
                    />
                    <span className="text-xs mt-1">Giờ</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.startDate
                          ? format(
                              new Date(item?.event?.profit?.startDate),
                              "mm"
                            )
                          : "N/A"
                      }
                      className="text-center w-10 bg-[#243959] text-white rounded"
                      readOnly
                    />
                    <span className="text-xs mt-1">Phút</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.startDate
                          ? format(
                              new Date(item?.event?.profit?.startDate),
                              "ss"
                            )
                          : "N/A"
                      }
                      className="text-center w-10 bg-[#243959] text-white rounded"
                      readOnly
                    />
                    <span className="text-xs mt-1">Giây</span>
                  </div>

                  {/* End Date */}
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.endDate
                          ? format(new Date(item?.event?.profit?.endDate), "dd")
                          : "N/A"
                      }
                      className={`text-center w-10 ${
                        isEnded
                          ? "bg-[#243959] text-red-500"
                          : "bg-[#243959] text-white"
                      } rounded`}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.endDate
                          ? format(new Date(item?.event?.profit?.endDate), "MM")
                          : "N/A"
                      }
                      className={`text-center w-10 ${
                        isEnded
                          ? "bg-[#243959] text-red-500"
                          : "bg-[#243959] text-white"
                      } rounded`}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.endDate
                          ? format(
                              new Date(item?.event?.profit?.endDate),
                              "yyyy"
                            )
                          : "N/A"
                      }
                      className={`text-center w-10 ${
                        isEnded
                          ? "bg-[#243959] text-red-500"
                          : "bg-[#243959] text-white"
                      } rounded`}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.endDate
                          ? format(new Date(item?.event?.profit?.endDate), "HH")
                          : "N/A"
                      }
                      className={`text-center w-10 ${
                        isEnded
                          ? "bg-[#243959] text-red-500"
                          : "bg-[#243959] text-white"
                      } rounded`}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.endDate
                          ? format(new Date(item?.event?.profit?.endDate), "mm")
                          : "N/A"
                      }
                      className={`text-center w-10 ${
                        isEnded
                          ? "bg-[#243959] text-red-500"
                          : "bg-[#243959] text-white"
                      } rounded`}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <input
                      type="text"
                      value={
                        item?.event?.profit?.endDate
                          ? format(new Date(item?.event?.profit?.endDate), "ss")
                          : "N/A"
                      }
                      className={`text-center w-10 ${
                        isEnded
                          ? "bg-[#243959] text-red-500"
                          : "bg-[#243959] text-white"
                      } rounded`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Events;
