import { ROUTES } from "config/routes";
import { Route, Switch } from "react-router-dom";
import Events from "./events";

const Smart = (props: any) => {
  return (
    <Switch>
      <Route path={ROUTES.SMART_EVENTS} component={Events} />
    </Switch>
  );
};

export default Smart;
