import { formatNumber } from "utils/common";

const VReward = ({ reward }) => {
  return (
    <div>
      {reward["stocks"] && reward["stocks"].length > 0 && (
        <>
          <label>Stocks :</label>
          {reward["stocks"].map((s) => (
            <div>
              <span>
                +{formatNumber(s.amount)} {s.is_percent ? "%" : ""} (
                {String(s.symbol).toUpperCase()})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}
      {reward["vouchers"] && reward["vouchers"].length > 0 && (
        <>
          <label>Vouchers :</label>
          {reward["vouchers"].map((s) => (
            <div>
              <span>
                +{formatNumber(s.amount)} ({s.id})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}

      {reward["tickets"] && reward["tickets"].length > 0 && (
        <div className="w-full">
          {reward["tickets"].map((s) => (
            <div className="w-full">
              <div className="flex justify-between w-full py-1 gap-2">
                <div className="flex flex-col ">
                  <label>Tickets :</label>
                  <span className="whitespace-nowrap  ">
                    +{formatNumber(s.amount)} ({s.game_name})
                  </span>
                </div>
                <div className="flex flex-col justify-around h-full pr-2">
                  <label>Image:</label>
                  <img
                    src={s.image}
                    alt="image_ticket"
                    className="w-10 h-auto"
                  />
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
      )}

      {reward["codes"] && reward["codes"].length > 0 && (
        <>
          <label>Codes :</label>
          {reward["codes"].map((s) => (
            <div>
              <span>
                +{s.codes} ({s.symbol})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}

      {reward["products"] && reward["products"].length > 0 && (
        <>
          <label>Products :</label>
          {reward["products"].map((s) => (
            <div className="flex items-center gap-x-1">
              {s.image && <img src={s.image} alt={s.name} className="size-4" />}
              <span>
                +{s.amount} ({s.name})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}
    </div>
  );
};

export default VReward;
