import PurchaseService from "api/purchases";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import VModal from "components/v-core/v-modal";
import VTable from "components/v-core/v-table";
import { MasterContext } from "context/MasterContext";
import Icons from "helper/icons";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatNumber } from "utils/common";
import { renderAmount, renderStock } from "utils/render";
import HistoryDividend from "./components/History";

const Dividends = () => {
  const { stocks, stocksPrice } = useContext(MasterContext);

  const [dividends, setDividends] = useState<any>([]);
  const [selected, setSelected] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<string>("");
  const [dividendAmount, setDividendAmount] = useState({
    amountFrom: null,
    amountTo: null,
  });

  const columns = [
    {
      key: "Package",
      name: "Package",
      render: (item) => {
        return `${item.package.name} (${item.package.type})`;
      },
    },
    {
      key: "claim",
      name: "Claim",
      render: (item) => (
        <VButton
          disabled={!item.package.is_claim_dividend}
          type="outline"
          className={`flex items-center gap-x-2 !border-none px-4 py-2 text-sm cursor-pointer`}
          onClick={() => {
            setSelected(item);
            setOpenModal("claim_dividend");
          }}
          iconLeft={<Icons iconName="claim_profit" />}
        />
      ),
    },
    {
      key: "history",
      name: "History",
      render: (item: any) => (
        <VButton
          onClick={() => {
            setSelected(item);
            setOpenModal("history");
          }}
          type="outline"
          className="flex items-center gap-x-2 !border-none px-4 py-2 text-sm cursor-pointer"
          iconLeft={<Icons iconName="histories" />}
        />
      ),
    },
    {
      key: "total_round_invested",
      name: "Total dividens share",
      render: (item) => (
        <span>{renderAmount(item?.package.count.total_round_invested)}</span>
      ),
    },
    {
      key: "market_invested",
      name: "Dividends/Package",
      render: (item) => (
        <span>{renderAmount(item?.package.count.amount_invested)}</span>
      ),
    },
    {
      key: "percent",
      name: "Market percent",
      render: (item) => <span>{item?.package.dividend.percent} %</span>,
    },
    {
      key: "total",
      name: "My package dividends",
      render: (item) => <span>{renderAmount(item?.total)}</span>,
    },
    {
      key: "dividend",
      name: "Dividend",
      render: (item) => {
        return (
          <div className="flex flex-col gap-y-2">
            <div className="flex gap-x-1">
              <span className="opacity-60">Available:</span>
              <span>
                {formatNumber(item.available)}(
                {renderStock(item.package.invest.stock)})
              </span>
            </div>
            <div className="flex gap-x-1">
              <span className="opacity-60"> Claimed:</span>
              <span className="text-green-500">
                {formatNumber(item.claimed)}(
                {renderStock(item.package.invest.stock)})
              </span>
            </div>
            <div className="flex gap-x-1">
              <span className="opacity-60">Remaining:</span>
              <span className="text-red-500">
                {formatNumber(item.remain, 0)}(
                {renderStock(item.package.invest.stock)})
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getDividends();
  }, []);

  const getDividends = async () => {
    try {
      const res: any = await PurchaseService.getDividends({});
      setDividends(res);
    } catch (error) {}
  };

  const handleClaimDividend = async () => {
    try {
      setLoading(true);
      const res = await PurchaseService.claimDividend({
        body: {
          package_id: selected?.package._id,
          amount: dividendAmount.amountFrom,
        },
      });
      if (res) {
        setLoading(false);
        setOpenModal("");
        setSelected(null);
        setDividendAmount({
          amountFrom: null,
          amountTo: null,
        });
        getDividends();
        toast.success("Claimed success !");
      }
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const renderModalClaim = (selected) => {
    if (selected && selected?.package.dividend) {
      const from = selected?.package.invest.stock;
      const to = selected?.package.dividend.stock_claim;
      const fromStock = stocks.find((s) => s.symbol === from);
      const toStock = stocks.find((s) => s.symbol === to);

      const price = stocksPrice[to]?.["vnd"] / stocksPrice[from]?.["vnd"];

      const handleChangeAmount = (name: string, value: any) => {
        if (name === "from") {
          setDividendAmount((prev: any) => ({
            ...prev,
            amountFrom: +value,
            amountTo: +value / +price,
          }));
        } else {
          setDividendAmount((prev: any) => ({
            ...prev,
            amountFrom: +value * +price,
            amountTo: +value,
          }));
        }
      };

      return (
        <div>
          <div className="flex justify-center items-center">
            <img className="w-6 h-6" src={selected?.package.icon} alt="" />
            <span className="text-lg font-semibold">
              {selected?.package.name}
            </span>
          </div>
          <div>
            <div className="flex flex-col gap-y-1 ">
              <div className="flex items-center gap-x-1">
                <img
                  className="size-4 object-cover rounded-full"
                  src={fromStock?.logo || ""}
                  alt=""
                />
                <span>{from.toUpperCase()}</span>
              </div>
              <VInput
                name="amountFrom"
                isCurrency={true}
                value={dividendAmount.amountFrom}
                placeholder="0.00"
                onValueChange={(e) => handleChangeAmount("from", e)}
                className="!w-full"
              />
              <div className="font-semibold text-green-500">
                <span>Available: </span>
                <span>{formatNumber(selected?.remain || 0)}</span>
              </div>
            </div>
            <div className="flex my-2 justify-center">
              <img src="/assets/images/icons/transfer.svg" alt="" />
            </div>
            <div className="flex flex-col gap-y-1">
              <div className="flex items-center gap-x-1">
                <img
                  className="size-4 object-cover rounded-full"
                  src={toStock?.logo || ""}
                  alt=""
                />
                <span>{to.toUpperCase()}</span>
              </div>
              <VInput
                isCurrency={true}
                name="amountTo"
                value={dividendAmount.amountTo}
                placeholder="0.00"
                onValueChange={(e) => handleChangeAmount("to", e)}
                className="!w-full"
              />
              <div className="flex items-center justify-center gap-x-2 font-semibold text-blue-400">
                <span>Rate:</span>
                <span>
                  1 {String(to).toUpperCase()} = {price.toFixed(2)}{" "}
                  {String(from).toUpperCase()}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <VTable columns={columns} data={dividends} />
      <VModal
        isOpen={openModal === "claim_dividend"}
        onCancel={() => {
          setOpenModal("");
          setSelected(null);
        }}
        onConfirm={handleClaimDividend}
        centered={true}
        children={renderModalClaim(selected)}
        loading={loading}
      />
      <VModal
        isOpen={openModal === "history"}
        onCancel={() => {
          setOpenModal("");
          setSelected(null);
        }}
        title="Dividends history"
        centered={true}
        children={<HistoryDividend package_id={selected?.package._id} />}
        loading={loading}
      />
    </>
  );
};

export default Dividends;
