/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import StoryBooks from "./pages/story-books";
import AuthRoute from "./pages/auth";
import MyRoute from "./pages/my";
import { ROUTES } from "./config/routes";
import { ToastContainer } from "react-toastify";
import AOS from "aos";
import CreateEstock from "./pages/create-landing-page";
import BusinessLandingPage from "pages/business-landing-page";
import Invoice from "pages/invoice/invoice-ico";
import InvoicePayment from "pages/invoice/invoice-payment";
import CertificateHoldStocks from "pages/certificate/hold-stocks/View";
import WebviewProfit from "pages/web-view/profit";
import Reward from "layouts/Reward";
import InvoiceInvest from "pages/invoice/invoice-invest";
import InvoiceProduct from "pages/invoice/invoice-product";
import WebviewRound from "pages/web-view/round";
import { MasterContext } from "context/MasterContext";
import { SocketContext } from "context/SocketContext";
import { ThemeContext } from "context/ThemeContext";
import ScanQR from "components/scanQR";

import "react-toastify/dist/ReactToastify.css";
import "./assets/sass/global.scss";
import "aos/dist/aos.css";
import EventLangdingPage from "pages/events";
import Smart from "pages/smart";
import Smart_my from "pages/my/smart";

function App() {
  const {
    auth,
    reward,
    getStocksPrice,
    getBalances,
    getSettings,
    getStocks,
    getRate,
    getMenus,
    getCountries,
  } = useContext(MasterContext);
  const { connectSocket, connectSocketAd } = useContext(SocketContext);
  const { getThemesSetting, theme } = useContext(ThemeContext);

  useEffect(() => {
    connectSocket();
    connectSocketAd();
  }, []);

  useEffect(() => {
    if (auth) {
      getMenus();
      getBalances();
      getCountries();
    }
  }, [auth]);

  useEffect(() => {
    if (theme) {
      getThemesSetting(theme);
    }
  }, [theme]);

  useEffect(() => {
    AOS.init({
      duration: 1500,
      delay: 300,
      disable: "mobile",
    });
    getSettings();
    getStocksPrice();
    getStocks();
    getRate();
  }, []);

  // const snowFallSetting: any = {
  //   color: "#fff",
  //   rotate: 0,
  //   rotationSpeed: [-2, 1],
  //   speed: [0, 0.1],
  //   radius: [20, 30],
  //   snowflakeCount: 15,
  //   icon_url:
  //     "https://file.vdiarybook.com/api/files/users/6460e8b9cc94cad4e2467d65/posts/7be90784-fb3b-4e68-b141-577e2f5f3dea--700.png",
  // };

  // const snowFallImages = useMemo(() => {
  //   if (snowFallSetting?.icon_url) {
  //     const snowflake = document.createElement("img");
  //     snowflake.src = snowFallSetting?.icon_url;
  //     return [snowflake];
  //   }

  //   return [];
  // }, [snowFallSetting]);

  return (
    <div
      className="App"
      // style={themesSetting.layout}
    >
      {reward && <Reward />}
      <Switch>
        <Route path="/auth" component={AuthRoute} />
        <Route path="/scan" component={ScanQR} />
        <Route path="/my" component={MyRoute} />
        <Route
          path={ROUTES.LANGDINGPAGE_PROJECT}
          component={BusinessLandingPage}
        />
        <Route
          path={ROUTES.SMART_EVENTS_CREATE}
          component={EventLangdingPage}
        />
        <Route
          path={[
            // "/create-your-estock/profit/:projectId/:token",
            "/create-your-estock/:symbol",
            "/create-your-estock",
          ]}
          component={CreateEstock}
        />
        <Route path={ROUTES.STORY_BOOK} component={StoryBooks} />
        <Route path={ROUTES.INVOICE} component={Invoice} />
        <Route path={ROUTES.INVOICE_PAYMENT} component={InvoicePayment} />
        <Route path={ROUTES.INVOICE_INVEST} component={InvoiceInvest} />
        <Route path={ROUTES.INVOICE_PRODUCT} component={InvoiceProduct} />
        <Route path={ROUTES.WEB_VIEW} component={WebviewProfit} />
        <Route path={ROUTES.WEB_VIEW_ROUND} component={WebviewRound} />
        <Route
          path={ROUTES.CERTIFICATE_HOLD_STOCK}
          component={CertificateHoldStocks}
        />
        <Route path={ROUTES.SMART_EVENTS} component={Smart} />
        <Route path={ROUTES.SMART_EVENTS_MY_LIST} component={Smart_my} />

        {/* <Route path={"/smart"} component={Smart} /> */}
        <Route path="/" component={AuthRoute} />
      </Switch>
      {/* <Support /> */}
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
