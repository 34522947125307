/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import APIs from "api";
import React, { useEffect, useState } from "react";
import {
  renderAmount,
  renderStatus,
  renderStock,
  renderTransactionType,
} from "utils/render";
import VTable from "components/v-core/v-table";
import VTabs from "components/v-core/v-tabs";
import { useHistory, useParams } from "react-router-dom";
import useQuery from "hooks/useQuery";
import Icons from "helper/icons";
import { formatNumber } from "utils/common";
import VModal from "components/v-core/v-modal";
import VPagination from "components/v-core/v-pagination";

const tabs = [
  {
    name: "Changes",
    value: "changes",
  },
  {
    name: "Deposit",
    value: "deposit",
  },
  {
    name: "Withdraw",
    value: "withdraw",
  },
  {
    name: "Lock and unlock",
    value: "locks",
  },
];

const View = () => {
  const [dataHistory, setDataHistory] = useState<any>({
    docs: [],
    totalPages: 0,
    totalDocs: 0,
  });

  const [dataLocks, setDataLocks] = useState([]);
  const history = useHistory();
  const query = useQuery();
  const { stock } = useParams();

  const currentTab = query?.tab;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [images, setImages] = useState([]);
  const [filter, setFilter] = useState({ page: 1, limit: 6 });

  const handleClickImage = (images: any) => {
    setImages(images);
    setIsOpenModal(true);
  };

  const onFilter = (type: string) => {
    if (type === "next") {
      setFilter((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        page: prev.page - 1,
      }));
    }
  };

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (data: any) => <span>{data.from.slice(0, 20)}...</span>,
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (data: any) => <span>{data.to.slice(0, 20)}...</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <span className="text-orange-400">{renderAmount(data.amount)}</span>
      ),
    },
    {
      title: "Amount Locked",
      dataIndex: "amountLocked",
      key: "amountLocked",
      render: (data: any) => (
        <span className="text-orange-400">
          {renderAmount(data.amountLocked)}
        </span>
      ),
    },
    {
      title: "Amount Received",
      dataIndex: "amountReceived",
      key: "amountReceived",
      render: (data: any) => (
        <span className="text-orange-400">
          {renderAmount(data.amountReceived || data.amount)}
        </span>
      ),
    },
    currentTab === "changes"
      ? {
          title: "Type",
          dataIndex: "type",
          key: "type",
          render: (data: any) => (
            <span>{renderTransactionType(data.type)}</span>
          ),
        }
      : {
          title: "Fee",
          dataIndex: "fee",
          key: "fee",
          render: (data: any) => (
            <>
              {" "}
              <span className="text-orange-400">
                {formatNumber(data.fee.amount)}
              </span>{" "}
              ({renderStock(data.fee.symbol)})
            </>
          ),
        },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => renderStatus(data.status),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString()}</span>
      ),
    },
    {
      key: "hash",
      title: "Hash",
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.hash?.slice(0, 10)}...
        </a>
      ),
    },
    {
      key: "invoice",
      title: "Invoice",
      render: (item: any) => (
        <a
          href={`/invoice/transactions/${currentTab}/${item._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          <Icons iconClassName="w-8 h-8" iconName="view" />
        </a>
      ),
    },
    currentTab === "withdraw" && {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: (data: any) => (
        <span
          title={data.note}
          className="max-w-full text-ellipsis overflow-hidden cursor-default"
        >
          {data.note ? `${data.note.slice(0, 5)}...` : ""}
        </span>
      ),
    },
    {
      title: "Images",
      dataIndex: "images",
      key: "images",
      render: (data: any) => {
        const _image = data.attachment?.images?.slice(0, 1).find((el) => el);
        if (_image) {
          return (
            <img
              className="max-w-full max-h-full cursor-pointer"
              onClick={() => handleClickImage(data.attachment?.images)}
              src={_image?.url}
              alt=""
            />
          );
        }
      },
    },
  ];

  const columnsLock = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <span className=" text-orange-400">{renderAmount(data.amount)}</span>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString()}</span>
      ),
    },
  ];

  useEffect(() => {
    if (!currentTab) {
      history.push(`?tab=changes`);
    }
    setFilter({ page: 1, limit: 6 });
  }, [currentTab]);

  useEffect(() => {
    if (stock && currentTab) getHistory();
  }, [currentTab, stock]);

  useEffect(() => {
    getHistory();
  }, [filter]);

  const getHistory = async () => {
    try {
      if (currentTab === "locks") {
        const res: any = await APIs.TRANSACTION.getLocks({
          params: {
            stock: stock,
          },
        });
        if (res && res.content) {
          setDataLocks(res.content);
        }
      } else {
        const res: any = await APIs.TRANSACTION.getTransactions({
          query: {
            stock: stock,
            type: currentTab,
            ...filter,
          },
        });
        setDataHistory(res);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
  };

  return (
    <>
      <div className="w-full flex justify-center mb-6">
        <span className="text-lg font-semibold">
          Histories - {renderStock(stock)}
        </span>
      </div>
      <VTabs tabActive={currentTab} tabs={tabs} onChangeTabs={handleTabs} />
      <div className="min-h-[600px]">
        {currentTab === "locks" ? (
          <>
            <VTable columns={columnsLock} data={dataLocks} />
          </>
        ) : (
          <>
            <VTable columns={columns} data={dataHistory.docs} />
            <VPagination
              page={filter?.page}
              totalPages={dataHistory?.totalPages}
              limit={filter?.limit}
              totalDocs={dataHistory?.totalDocs}
              onFilter={onFilter}
            />
          </>
        )}
      </div>
      <VModal
        title={"Images"}
        isOpen={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
      >
        <div className="flex flex-wrap justify-center gap-3 mt-3">
          {images &&
            images.map((image) => (
              <div className="w-40 h-40">
                <img
                  className="w-full h-auto max-h-full rounded object-cover"
                  src={image.url}
                  alt=""
                />
              </div>
            ))}
        </div>
      </VModal>
    </>
  );
};

export default View;
