import { ROUTES } from "config/routes";
import { Route, Switch } from "react-router-dom";
import Events_my from "./events";

const Smart_my = (props: any) => {
  return (
    <Switch>
      <Route path={ROUTES.SMART_EVENTS_MY_LIST} component={Events_my} />
    </Switch>
  );
};

export default Smart_my;
