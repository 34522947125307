import APIs from "api";
import VModal from "components/v-core/v-modal";
import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import VUploadFile from "components/v-upload";
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Marquee from "react-fast-marquee";
import { CgLogIn } from "react-icons/cg";

type IProps = {
  data?: any;
  setData?: (e: any) => void;
  isEdit?: boolean;
};

const Header = (props: IProps) => {
  const { data, setData, isEdit } = props;
  const { handleSwitchTheme, theme } = useContext(ThemeContext);
  const params = useParams();
  const [typeModal, setTypeModal] = useState("");
  const [isOpenModalDelete, setIsOpenModalMenuDelete] = useState(false);
  const [menuSelected, setMenuSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [logo, setLogo] = useState(null);
  const [links, setLinks] = useState({
    linkLogin: data.linkLogin,
    linkRegister: data.linkRegister,
  });

  const onChangeInput = (value: string, name?: string, type?: string) => {
    if (type === "menu") {
      setMenuSelected((prev) => ({ ...prev, [name]: value }));
    } else {
      setLinks({
        ...links,
        [name]: value,
      });
    }
  };

  // Links
  const openTagWindow = (url: string) => {
    window.open(url);
  };

  // Modal
  const openModal = (type: string) => {
    setTypeModal(type);
    if (type === "menu") {
      setMenuSelected({
        id: uuidv4(),
        name: "",
        value: "",
      });
    }
  };

  const onCancelModal = () => {
    setTypeModal("");
  };

  const onSubmitModal = async (name?: string) => {
    setIsLoading(true);
    if (typeModal === "logo") {
      const formData = new FormData();
      formData.append("folderPath", `landing-page/${params.symbol}`);

      formData.append("files", logo?.file);
      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      listImg.forEach((el) =>
        setData((prev) => ({
          ...prev,
          logo: el.filePath,
        }))
      );
    }
    if (typeModal === "menu") {
      setData((prev) => ({
        ...prev,
        menus: prev.menus.concat({ ...menuSelected }),
      }));
    }
    if (typeModal === "login" || typeModal === "register") {
      setData((prev) => ({
        ...prev,
        ...links,
      }));
    }
    setIsLoading(false);
    setTypeModal("");
  };

  const openModalDelete = (menu: any) => {
    setIsOpenModalMenuDelete(true);
    setMenuSelected(menu);
  };

  const cancelModalDelete = () => {
    setIsOpenModalMenuDelete(false);
    setMenuSelected(null);
  };

  const handleDeleteMenu = () => {
    const _menus = data.menus?.filter((el) => el.id !== menuSelected.id);
    setData((prev) => ({
      ...prev,
      menus: [..._menus],
    }));
    setIsOpenModalMenuDelete(false);
  };

  // Logo
  const setFile = (f: any, id: string) => {
    setLogo((prev) => ({
      ...prev,
      file: f,
      isNew: true,
    }));
  };
  return (
    <>
      <div className="px-2">
        {data?.logo && (
          <Marquee className="w-screen">
            <img className="h-14 w-14 object-cover" src={data.logo} alt="" />
          </Marquee>
        )}
        <div
          className={`${isEdit && " "}  pt-2 rounded-md px-2`}
          onClick={() => isEdit && openModal("logo")}
        >
          {isEdit && (
            <VButton className="h-full flex justify-center items-center cursor-pointer">
              <Icons iconName="upload" />
              <span>Upload</span>
            </VButton>
          )}
        </div>
      </div>
      <div className="relative max-lg:hidden px-4 py-6 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-7xl">
          <div className="hidden sm:block">
            <nav className="flex">
              <ul
                role="list"
                className="flex min-w-full flex-none gap-x-6 px-2 text-sm font-semibold leading-6 m-0"
              >
                {data?.menus?.length > 0 &&
                  data.menus?.map((menu) => (
                    <li
                      key={menu.id}
                      className="relative cursor-pointer hover:opacity-70"
                      onClick={() => !isEdit && openTagWindow(menu.value)}
                    >
                      <span className="text-base">{menu.name}</span>
                      {isEdit && (
                        <div className="absolute -right-3 -top-3">
                          <VButton
                            className="w-4 h-4"
                            onClick={() => openModalDelete(menu)}
                          >
                            -
                          </VButton>
                        </div>
                      )}
                    </li>
                  ))}
                {isEdit && (
                  <VButton
                    className="absolute right-0 -top-1 w-8 h-8"
                    radius="full"
                    onClick={() => openModal("menu")}
                  >
                    +
                  </VButton>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <VModal
        title={"Are you sure delete this menu"}
        isOpen={isOpenModalDelete}
        onConfirm={handleDeleteMenu}
        onCancel={cancelModalDelete}
      ></VModal>
      <VModal
        loading={isLoading}
        title={typeModal}
        isOpen={typeModal !== ""}
        onCancel={onCancelModal}
        onConfirm={onSubmitModal}
      >
        <div className="w-full flex flex-col items-center gap-y-3">
          {typeModal === "logo" && (
            <div className="h-48 w-48">
              <VUploadFile
                title="logo"
                radius="md"
                id="logo"
                file={logo?.file}
                setFile={setFile}
                src={data.logo}
              />
            </div>
          )}
          {typeModal === "menu" && (
            <>
              <VInput
                label={"Name"}
                value={menuSelected?.name}
                onChange={(e) => onChangeInput(e.target.value, "name", "menu")}
              />
              <VInput
                label={"Value"}
                value={menuSelected?.value}
                onChange={(e) => onChangeInput(e.target.value, "value", "menu")}
              />
            </>
          )}
          {typeModal === "login" && (
            <>
              <VInput
                label={"Link"}
                value={links?.linkLogin}
                onChange={(e) => onChangeInput(e.target.value, "linkLogin")}
              />
            </>
          )}
          {typeModal === "register" && (
            <VInput
              label={"Link"}
              value={links?.linkRegister}
              onChange={(e) => onChangeInput(e.target.value, "linkRegister")}
            />
          )}
        </div>
      </VModal>
    </>
  );
};

export default Header;
