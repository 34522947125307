import APIs from "api";
import VButton from "components/v-core/v-button";
import { useContext } from "react";
import { toast } from "react-toastify";
import { signMessage } from "utils/ethers";
import AuthAPI from "api/auth";
import { ROUTES } from "config/routes";
import { ThemeContext } from "context/ThemeContext";
import { useHistory } from "react-router-dom";

const SocialsLogin = () => {
  const { theme } = useContext(ThemeContext);
  const history = useHistory();

  const loginByWallet = async () => {
    try {
      const { ethereum }: any = window;
      if (!ethereum) toast.error("No crypto wallet found. Please install it.");
      await ethereum.send("eth_requestAccounts");
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      const res = await APIs.USER.getNonce({
        params: {
          address: accounts[0].toLowerCase(),
        },
      });
      const { nonce }: any = res;
      const sign = await signMessage(nonce);
      if (sign) {
        const res: any = await AuthAPI.login(
          {
            sign,
          },
          { provider: "wallet" }
        );
        if (res.require_2fa || res.require_email) {
          history.push(
            `${ROUTES.VERIFY_LOGIN}?twofa=${res.require_2fa}&&email=${res.require_email}&&token=${res.token}`
          );
        } else {
          if (res && res.user) {
            localStorage.setItem("auth", res.token);
            history.push("/my/dashboard");
          }
        }
      }
    } catch (error) {
      toast.error(error.errors || "Something went error!");
    }
  };

  const loginByVzonex = () => {
    window.open(
      `https://vzonex.com/auth/login?callback=${window.origin}/auth/login/token-callback`,
      "_blank"
    );
  };

  const loginByVdiarybook = () => {
    window.open(
      `https://vdiarybook.com/?callback=${window.origin}/auth/login/token-callback`,
      "_blank"
    );
  };

  return (
    <div className="flex flex-col w-full gap-y-4">
      <div className="flex items-center">
        <div className="h-[1px] bg-gray-500 w-[45%]"></div>
        <p
          className={`flex items-center justify-center text-xs m-0 w-[10%] font-semibold ${
            theme === "dark" ? "text-gray-400" : "text-gray-500"
          }`}
        >
          or
        </p>
        <div className="h-[1px] bg-gray-500 w-[45%]"></div>
      </div>
      <div className="grid grid-cols-6 gap-2">
        <VButton
          className=" py-3 "
          iconLeftClassName={"absolute left-3 top-1/2 -translate-y-1/2"}
          onClick={loginByWallet}
        >
          <img
            className="w-7 h-7 object-cover"
            src="/assets/images/social/metamask.png"
          />
        </VButton>
        <VButton
          className=" py-3 !bg-gray-3"
          iconLeftClassName={"absolute left-3 top-1/2 -translate-y-1/2"}
          onClick={loginByVdiarybook}
        >
          <img
            className="w-7 h-7 object-cover"
            src="/assets/images/social/vdiarybook.png"
          />
        </VButton>
        <VButton
          className="py-3 !bg-gray-3"
          iconLeftClassName={"absolute left-3 top-1/2 -translate-y-1/2"}
          onClick={loginByVzonex}
        >
          <img
            className="w-7 h-7 object-cover"
            src="/assets/images/social/vzonex.png"
          />
        </VButton>
        <VButton
          className=" py-3 !bg-gray-3"
          iconLeftClassName={"absolute left-3 top-1/2 -translate-y-1/2"}
          disabled
        >
          <img
            className="w-7 h-7 object-cover"
            src="/assets/images/social/google.png"
          />
        </VButton>
        <VButton
          className="py-3 !bg-gray-3"
          iconLeftClassName={"absolute left-3 top-1/2 -translate-y-1/2"}
          disabled
        >
          <img
            className="w-7 h-7 object-cover"
            src="/assets/images/social/facebook.png"
          />
        </VButton>
        <VButton
          className="py-3 !bg-gray-3"
          iconLeftClassName={"absolute left-3 top-1/2 -translate-y-1/2"}
          disabled
        >
          <img
            className="w-5 h-5 object-cover"
            src="/assets/images/social/twitter.png"
          />
        </VButton>
      </div>
    </div>
  );
};

export default SocialsLogin;
